import React from 'react';

const crystals = [
  {
    name: 'Terra',
    imageSrc: {
      webp: require('@/assets/images/game/crystals/terra.webp'),
      png: require('@/assets/images/game/crystals/terra.png'),
    },
    readyToMint: '-',
    minted: '-',
  },
  {
    name: 'Atmos',
    imageSrc: {
      webp: require('@/assets/images/game/crystals/atmos.webp'),
      png: require('@/assets/images/game/crystals/atmos.png'),
    },
    readyToMint: '-',
    minted: '-',
  },
  {
    name: 'Aqua',
    imageSrc: {
      webp: require('@/assets/images/game/crystals/aqua.webp'),
      png: require('@/assets/images/game/crystals/aqua.png'),
    },
    readyToMint: '-',
    minted: '-',
  },
];

const Crystals = () => {
  return (
    <div className="">
      <div className="overflow-auto pb-2 -mx-container md:mx-0">
        <div className="flex gap-2 rounded-t-[32px]">
          {crystals.map((crystal) => (
            <div
              className="flex-shrink-0 first:pl-container last:pr-container md:first:px-0 md:last:pr-0 sm:flex-1 group"
              key={crystal.name}
            >
              <div
                className="group-first:rounded-tl-[32px] group-last:rounded-tr-[32px] group-first:overflow-hidden group-last:overflow-hidden bg-[#141414] pt-5 px-2.5 pb-2.5 w-64 sm:w-auto"
                key={crystal.name}
              >
                <div className="mb-2.5 max-w-[172px] mx-auto aspect-square">
                  <picture>
                    <source srcSet={crystal.imageSrc.webp} type="image/webp" />
                    <source srcSet={crystal.imageSrc.png} type="image/png" />
                    <img src={crystal.imageSrc.png} alt="crystal" />
                  </picture>
                </div>

                <div className="mb-5 text-center text-3xl font-HelveticaDisplay font-extrabold text-lightgrey">
                  {crystal.name}
                </div>

                <div className="flex text-center">
                  <div className="flex-1 px-5 py-2.5">
                    <div className="mb-1 text-grey text-xs">Ready to mint</div>
                    <div className="text-3xl text-lightgrey font-bold">
                      {crystal.readyToMint}
                    </div>
                  </div>
                  <div className="flex-1 px-5 py-2.5">
                    <div className="mb-1 text-grey text-xs">Minted</div>
                    <div className="text-3xl text-lightgrey font-bold">
                      {crystal.minted}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-[#141414] col-span-3 rounded-b-[32px] overflow-hidden">
        <div className="px-10 py-5 text-center">
          <div className="mb-1.5 text-3xl text-lightgrey font-HelveticaDisplay font-extrabold">
            Mint all my crystals
          </div>
          <div className="text-darkgrey text-xs">
            You can find more crystals in our Game app
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crystals;
