import React from 'react';

import OtpVerification from 'components/common/OtpVerification';

const OtpCode = ({
  title,
  email,
  error,
  onChangeState,
  sendOtp,
  resendOtp,
  clearOtpError,
}) => {
  return (
    <div>
      {title}

      <OtpVerification
        email={email}
        error={error}
        onChangeState={onChangeState}
        sendOtp={sendOtp}
        resendOtp={resendOtp}
        clearOtpError={clearOtpError}
      />
    </div>
  );
};

export default OtpCode;
