import SvgIcon from 'components/ui/SvgIcon';
import React, { useEffect, useState, useMemo } from 'react';

const Modal = ({ achievement, onClose }) => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (!window?.matchMedia) return;

    const theme =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';

    setTheme(theme);
  }, []);

  const source = useMemo(() => {
    if (!achievement) return null;

    return achievement[theme]?.video_full || achievement[theme]?.image;
  }, [theme, achievement]);

  const isVideo = useMemo(
    () =>
      source &&
      ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.wmv'].some((i) =>
        source.includes(i),
      ),
    [source],
  );

  return (
    <div className="relative sm:rounded-2xl overflow-hidden bg-darklight shadow-md">
      <div className="aspect-square sm:h-[calc(100vh_-_140px)]">
        {isVideo ? (
          <video
            className="block h-full w-full object-contain"
            autoPlay
            loop
            muted
            src={source}
            poster={achievement[theme].image}
          />
        ) : (
          <img
            className="block h-full w-full object-contain"
            src={source}
            alt={achievement.name}
          />
        )}
      </div>
      <div className="max-w-2xl flex justify-between items-center mx-auto mt-4 px-4 pb-4">
        <div className="">
          <div className="text-white text-lg font-bold leading-none">
            {achievement.name}
          </div>
          <div className="mt-1.5 text-xs leading-none font-medium text-[#949494]">
            {achievement.description}
          </div>
        </div>
        <div className="ml-4 py-0.5 pr-4 pl-6 border-l border-[#949494]">
          <strong className="block font-extrabold text-2xl leading-snug text-green font-HelveticaDisplay">
            x{achievement.multiplier}
          </strong>
          <span className="block font-medium text-xs text-[#949494]">
            Multiplier
          </span>
        </div>
      </div>

      <button
        type="button"
        className="absolute top-4 right-4 z-[1] h-10 w-10 flex items-center justify-center cursor-pointer bg-[#424242]/40 text-white rounded-lg hover:bg-[#424242]/80 transition-all outline-none"
        onClick={onClose}
      >
        <SvgIcon name="close" />
      </button>
    </div>
  );
};

export default Modal;
