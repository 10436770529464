import React from 'react';

import StatInfoItem from 'components/common/StatInfoItem';

import styles from './statistic.module.scss';

const Statistic = () => {
  return (
    <div className={styles['statistic-list']}>
      <StatInfoItem
        title={<div className={styles['gradient-title']}>Delivered</div>}
      >
        <div className={styles['gradient-text']}>0</div>
      </StatInfoItem>

      <StatInfoItem
        title={<div className={styles['gradient-title']}>In progress</div>}
      >
        <div className={styles['gradient-text']}>0</div>
      </StatInfoItem>

      <StatInfoItem
        title={<div className={styles['gradient-title']}>NFT clothes</div>}
      >
        <div className={styles['gradient-text']}>0</div>
      </StatInfoItem>
    </div>
  );
};

export default Statistic;
