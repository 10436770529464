import React from 'react';

import styles from './button.module.scss';

const Button = ({ type = 'button', disabled = false, onClick, children }) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={styles.button}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
