import React, { useMemo } from 'react';
import classnames from 'classnames';

import widthClass from '../columnsWidth';

import Anonymous from './Anonymous';
import User from './User';
import Vote from './Vote';
import Project from './Project';
import Role from './Role';
import Status from './Status';
import TotalVotes from './TotalVotes';

const classBefore =
  'before:block md:before:hidden before:text-[13px] before:text-white/40 before:font-medium before:mb-2';

const addZero = (num) => {
  return num < 10 ? '0' + num : num;
};

const Item = ({ data }) => {
  const voteTime = useMemo(() => {
    const date = new Date(data.voted_at);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
  }, [data]);

  return (
    <div className="flex items-center md:space-x-5 xl:space-x-10 flex-wrap md:flex-nowrap bg-darklight md:bg-dark md:odd:bg-darklight md:bg-transparent rounded-2xl p-3 text-[15px] md:text-[17px] font-medium leading-none group">
      <div
        className={classnames(
          widthClass[0],
          'order-[3] md:order-none before:content-["Voter"]',
          classBefore,
        )}
      >
        {data.output_wallet ? <User data={data} /> : <Anonymous />}
      </div>

      <div
        className={classnames(
          widthClass[1],
          'ml-1 md:ml-0 order-[4] md:order-none before:content-["Role"]',
          classBefore,
        )}
      >
        <Role data={data} />
      </div>

      <div
        className={classnames(
          widthClass[2],
          'ml-auto md:ml-0 order-[5] md:order-none before:content-[attr(time)]',
          classBefore,
        )}
        time={`Vote ${voteTime}`}
      >
        <Vote
          vote={
            data?.role_name === 'scout' && data.vote_type === 'scam'
              ? 'suspicious_activity'
              : data.vote_type
          }
        />
      </div>

      <div
        className={classnames(
          widthClass[3],
          'order-first md:order-none before:content-["Project"]',
          classBefore,
        )}
      >
        <Project data={data} />
      </div>

      <div
        className={classnames(
          widthClass[4],
          'ml-2 md:ml-0 order-[1] md:order-none before:content-["Actual_status"]',
          classBefore,
        )}
      >
        <Status status={data.project_status} />
      </div>

      <div className={classnames(widthClass[5], 'hidden md:block text-[15px]')}>
        {voteTime}
      </div>

      <div
        className={classnames(
          widthClass[6],
          'mt-2 mb-6 md:my-0 w-full md:w-fit order-[2] md:order-none',
        )}
      >
        <TotalVotes data={data} />
      </div>
    </div>
  );
};

export default Item;
