import client from './client';

import { SAFESOUL_INFORMATION } from 'constants/urls';

import handleErrors from '@/utils/handleErros.js';
import { isAuthenticated } from 'utils/auth';

export const updateSoul = async ({ attributes, wallet }) => {
  if (!isAuthenticated()) return Promise.reject('Not authenticated');

  try {
    const { data } = await client('/api/dynamic-soul?wallet=' + wallet, {
      method: 'PUT',
      baseURL: process.env.REACT_APP_DA_URL,
      data: {
        attributes,
      },
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};
