import React from 'react';

import Switcher from 'components/ui/Switcher';
import SvgIcon from 'components/ui/SvgIcon';

import { useSafeSoulStore } from 'store';
import { SAFESOUL_SITE_LINK } from 'constants/urls';

const Settings = () => {
  const { controls, showLive } = useSafeSoulStore();

  const handleUpdateSetting = (key, value) => {
    controls.switchLive(value);
  };

  return (
    <div className="space-y-3">
      <div className="py-1.5 px-2">
        <Switcher
          checked={showLive}
          onChange={(e) =>
            handleUpdateSetting('showInScamStream', e.target.checked)
          }
        >
          <div className="flex items-center gap-3 text-white text-xs font-bold">
            <SvgIcon name="list" className="opacity-50" />
            <span>
              Show me on{' '}
              <a
                href={SAFESOUL_SITE_LINK + '/live'}
                target="_blank"
                rel="noreferrer"
                className="underline transition-all hover:opacity-80"
              >
                Scam stream
              </a>
            </span>
          </div>
        </Switcher>
      </div>
    </div>
  );
};

export default Settings;
