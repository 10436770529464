import { useCallback, useEffect, useState, useMemo } from 'react';

import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccountEffect, useSignMessage, useDisconnect } from 'wagmi';
import { SiweMessage } from 'siwe';
import { getNonce } from 'api/auth';

const generateMessage = ({
  address,
  chainId,
  nonce,
  expiration_time,
  session_key,
  issued_at,
}) => {
  const message = new SiweMessage({
    domain: process.env.REACT_DOMAIN_URI || window.location.host,
    address,
    statement: 'Sign in with Ethereum',
    uri: window.location.origin,
    version: '1',
    chainId,
    nonce,
    expirationTime: expiration_time,
    requestId: session_key,
    issuedAt: issued_at,
  });

  return message.prepareMessage();
};

let isIdle = true;
let isNeedConnect = false;

const useRainbowConnect = (onConnect, verify) => {
  const [connector, setConnector] = useState(null);
  const [address, setAddress] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isConnected = useMemo(() => !!address && !!chainId, [address, chainId]);

  const { connectModalOpen, openConnectModal } = useConnectModal();

  useAccountEffect({
    onConnect(data) {
      setConnector(data.connector.name);
      setAddress(data.address);
      setChainId(data.chainId);
    },
    onDisconnect(error) {
      setAddress(null);
      setChainId(null);
    },
  });
  const { signMessageAsync } = useSignMessage();
  const { disconnectAsync } = useDisconnect();

  const handleDisconnect = useCallback(async () => {
    try {
      isIdle = true;

      await disconnectAsync();

      setAddress(null);
      setChainId(null);

      isNeedConnect = false;
      setError(null);
    } catch (error) {
      console.log(error);
    }
  }, [disconnectAsync]);

  const handleConnect = useCallback(async () => {
    if (address === null || chainId === null) return;

    try {
      setError(null);
      setIsLoading(true);

      const { nonce, expiration_time, session_key, issued_at } =
        await getNonce();

      const message = generateMessage({
        address,
        chainId,
        nonce,
        expiration_time,
        session_key,
        issued_at,
      });

      const signature = await signMessageAsync({ message });

      const result = await verify({ message, signature });

      if (onConnect) onConnect({ result, address, connector });

      setIsLoading(false);

      isIdle = true;
      isNeedConnect = false;
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  }, [address, chainId, connector, signMessageAsync, verify, onConnect]);

  useEffect(() => {
    if (isConnected && isIdle && isNeedConnect) {
      isIdle = false;
      handleConnect();
    }
  }, [handleConnect, isConnected, chainId]);

  const connectWallet = useCallback(() => {
    isNeedConnect = true;

    if (isConnected) {
      isIdle = false;
      handleConnect();

      return;
    }

    if (connectModalOpen) return;

    openConnectModal();
  }, [handleConnect, isConnected, openConnectModal, connectModalOpen]);

  return { error, isLoading, handleDisconnect, connectWallet };
};

export default useRainbowConnect;
