import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import OtpInput from 'react-otp-input';

import styles from './codeVerification.module.scss';

const CodeVerification = ({
  error,
  onInputCode,
  onChangeCode,
  codeLength = 6,
}) => {
  const [otp, setOtp] = useState('');

  const handleChange = (otp) => {
    onChangeCode(otp);
    setOtp(otp);
  };

  useEffect(() => {
    if (otp.length === codeLength) {
      onInputCode(otp);
    }
  }, [codeLength, onInputCode, otp]);

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        {error ? (
          <div className={styles.error}>{error}</div>
        ) : (
          <div className={styles.title}>
            Please enter {codeLength}-digit code from e-mail
          </div>
        )}
      </div>

      <OtpInput
        value={otp}
        inputType="tel"
        onChange={handleChange}
        numInputs={codeLength}
        containerStyle={styles['code-inputs']}
        shouldAutoFocus
        renderInput={({ className, ...props }) => (
          <div
            className={classnames(styles.input, error && styles['is-error'])}
          >
            <div className={styles['input-wrapper']}>
              <div className={styles['input-inner']}>
                <input
                  type="tel"
                  className={styles['input-control']}
                  {...props}
                />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CodeVerification;
