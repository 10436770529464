import client from './client';

import { SAFESOUL_INFORMATION, SAFESOUL_UPDATE_LIVE } from 'constants/urls';

import handleErrors from '@/utils/handleErros.js';
import { isAuthenticated } from 'utils/auth';

export const getInformation = async () => {
  if (!isAuthenticated()) return Promise.reject('Not authenticated');

  try {
    const { data } = await client(SAFESOUL_INFORMATION, {
      method: 'GET',
      baseURL: process.env.REACT_APP_SAFESOUL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const toggleLive = async (live = false) => {
  if (!isAuthenticated()) return Promise.reject('Not authenticated');

  try {
    const { data } = await client(SAFESOUL_UPDATE_LIVE, {
      method: 'PUT',
      baseURL: process.env.REACT_APP_SAFESOUL_API,
      data: {
        live,
      },
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};
