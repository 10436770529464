import client, { clientAuth } from './client';

import handleErrors from '@/utils/handleErros.js';

import {
  WALLET_GET_NONCE,
  WALLET_VERIFY,
  SEND_OTP,
  LOGIN_OTP,
  LOGIN_GOOGLE_URL,
  LOGIN_APPLE_URL,
  LOGIN_GOOGLE,
  LOGIN_APPLE,
  LOGOUT,
} from 'constants/urls';

export const sendOtp = async ({ email }) => {
  try {
    await clientAuth.post(SEND_OTP, {
      email,
    });

    return 'success';
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const loginOtp = async ({ email, token }) => {
  try {
    const { data } = await clientAuth.post(LOGIN_OTP, {
      email,
      token,
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const getGoogleUrl = async (redirect_url) => {
  try {
    const { data } = await clientAuth.get(LOGIN_GOOGLE_URL, {
      params: {
        redirect_url,
      },
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const loginGoogle = async ({ state, code, redirect_url }) => {
  try {
    const { data } = await clientAuth.post(LOGIN_GOOGLE, {
      state,
      code,
      redirect_url,
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const getAppleUrl = async (redirect_url) => {
  try {
    const { data } = await clientAuth.get(LOGIN_APPLE_URL, {
      params: {
        redirect_url,
      },
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};
export const loginApple = async ({ code, state, redirect_url }) => {
  try {
    const { data } = await clientAuth.post(LOGIN_APPLE, {
      code,
      state,
      redirect_url,
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const logout = async () => {
  try {
    await client(LOGOUT, {
      method: 'DELETE',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
    });

    return 'success';
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const getNonce = async () => {
  try {
    const { data } = await clientAuth.get(WALLET_GET_NONCE);

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const verify = async ({ signature, message }) => {
  try {
    const {
      data: { refresh_token, access_token },
    } = await clientAuth.post(WALLET_VERIFY, {
      signature,
      message,
    });

    return { refresh_token, access_token };
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};
