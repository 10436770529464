import React from 'react';
import classnames from 'classnames';

import { ReactComponent as ClubLabel } from 'assets/icons/projects/club-label.svg';

import styles from './light-decoration.module.scss';

const LightDecotation = ({
  hasLabel = false,
  padding = 16,
  children,
  onClick,
}) => {
  return (
    <div
      className={classnames(
        styles.wrapper,
        hasLabel ? styles['has-label'] : styles['no-label'],
      )}
      onClick={onClick}
    >
      <div className={styles.inner} style={{ padding: `${padding}px` }}>
        <div className={styles.text}>{children}</div>
      </div>
      {hasLabel ? (
        <div className={styles.label}>
          <ClubLabel />
        </div>
      ) : null}
    </div>
  );
};

export default LightDecotation;
