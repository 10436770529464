import React from 'react';

import Decoration from 'components/ui/Decoration';

import styles from './statinfoitem.module.scss';

const StatInfoItem = ({ title, children }) => {
  return (
    <Decoration>
      <div className={styles.item}>
        <div className={styles.title}>{title}</div>
        <div className={styles.value}>{children}</div>
      </div>
    </Decoration>
  );
};

export default StatInfoItem;
