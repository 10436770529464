import React from 'react';

import ProfileHeader from 'components/common/ProfileHeader';
import Statistic from './Statistic';
import LightDecotation from 'components/ui/LightDecoration';
import GoStore from './GoStore';

const Sidebar = () => {
  return (
    <>
      <div>
        <ProfileHeader />

        <GoStore />

        <Statistic />

        <LightDecotation hasLabel>
          <div>
            <span>Early access to genesis box</span>
          </div>
          <div>
            <span>Personalization of your clothes</span>
          </div>
        </LightDecotation>
      </div>
    </>
  );
};

export default Sidebar;
