import React from 'react';

import Email from './Email';
import Rainbow from './Rainbow';
import Twitter from './Twitter';
import Discord from './Discord';

import styles from './connections.module.scss';

const Connections = () => {
  return (
    <div className={styles.connections}>
      <Rainbow />

      <Email />

      <Twitter />

      <Discord />
    </div>
  );
};

export default Connections;
