import React, { useMemo } from 'react';
import classnames from 'classnames';
import Img from 'components/ui/Image';
import truncateEthAddress from 'truncate-eth-address';
import { Tooltip } from 'react-tippy';

import { useUserStore, useConnectionsStore, usePortalStore } from 'store';

import { ReactComponent as DummyAvatar } from 'assets/icons/dummyAvatar.svg';

import getPoints from 'utils/getPoints';

import styles from './profileHeader.module.scss';
import SvgIcon from 'components/ui/SvgIcon';
import { ReactComponent as Loader } from 'assets/common/souls-club-anim-logo.svg';

const ProfileHeader = () => {
  const {
    user: { current_rank, total_points },
  } = usePortalStore();

  const connectionsStore = useConnectionsStore();
  const { user } = useUserStore((store) => store);
  const outWallet = user?.ensName || user?.wallet_address;

  const avatar = useMemo(() => {
    if (!user?.twitter?.profile_image_url) {
      return (
        <span className="block w-20 aspect-square">
          <DummyAvatar />
        </span>
      );
    }

    return (
      <Img
        src={user?.twitter?.profile_image_url}
        alt={user?.twitter?.user_name}
        key={user?.twitter?.profile_image_url}
        className="block w-20 aspect-square overflow-hidden rounded-2xl"
        decode="false"
        unloaded={
          <span className="block w-20 aspect-square">
            <DummyAvatar />
          </span>
        }
        loader={
          <span className="flex items-center justify-center w-full h-full [&>*]:!size-20">
            <Loader />
          </span>
        }
      />
    );
  }, [user?.twitter?.profile_image_url, user?.twitter?.user_name]);

  if (!user) return null;

  const countPoints = getPoints(total_points);

  return (
    <div className={styles.relative}>
      <div className={styles.top}>
        <div className={styles.avatar}>{avatar}</div>

        <div className={styles['social-stats-wrapper']}>
          <div className={styles['social-stats']}>
            {!outWallet && user?.email ? (
              <Tooltip title={user?.email} position="bottom">
                <div className={styles['social-stat']}>
                  <div className={styles['social-stat__label']}>
                    <SvgIcon name="email" size="16" />
                  </div>
                  <div className={styles['social-stat__value']}>
                    {user?.email}
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </div>

          <div className={styles['social-stats']}>
            {outWallet ? (
              <Tooltip title={outWallet} position="bottom">
                <div className={styles['social-stat']}>
                  <div className={styles['social-stat__label']}>
                    <SvgIcon name="wallet" size="16" />
                  </div>
                  <div className={styles['social-stat__value']}>
                    {truncateEthAddress(outWallet)}
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </div>

          <div className={styles['social-stats']}>
            {user?.twitter ? (
              <div className={styles['social-stat']}>
                <div className={styles['social-stat__label']}>
                  <SvgIcon name="X" size="16" />
                </div>
                <div className={styles['social-stat__value']}>
                  {user.twitter?.user_name}
                </div>
              </div>
            ) : (
              <button
                type="button"
                className={classnames(styles['social-stat'], styles['ligth'])}
                onClick={connectionsStore.connectTwitter}
              >
                <div className={styles['social-stat__label']}>
                  <SvgIcon name="X" size="16" />
                </div>
                <div className={styles['social-stat__value']}>Connect</div>
              </button>
            )}
          </div>

          <div className={styles['social-stats']}>
            <div className={classnames(styles['social-stat'], styles['small'])}>
              <div className={classnames(styles['social-stat__value'])}>
                {countPoints} {Number(total_points) === 1 ? 'diamond' : 'diamonds'}
              </div>
            </div>

            <div className={classnames(styles['social-stat'], styles['small'])}>
              <div
                className={classnames(
                  styles['social-stat__value'],
                  styles['bold'],
                )}
              >
                #{current_rank}
              </div>
            </div>

            <div className={classnames(styles['social-stat'], styles['small'])}>
              <div
                className={classnames(
                  styles['social-stat__value'],
                  styles['gradient'],
                  styles['bold'],
                )}
              >
                {/* х{user?.multyplayer?.total} */}х{0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
