import React from 'react';

import useTokensAnimal from 'hooks/useTokensAnimal';

import Onboarding from '@/components/common/Onboarding';
import TokenItem from '@/components/common/TokenCardItem';

const Main = () => {
  const { tokens, animals, attributes, tokenLoaded } = useTokensAnimal();

  return tokens.length > 0 || attributes > 0 ? (
    <>
      {tokens.length > 0 && (
        <>
          <div className="mb-5">
            <div className="text-[32px] font-HelveticaDisplay font-extrabold text-lightgrey mb-2">
              Digital Animals
            </div>
            <div className="text-sm text-grey max-w-xs space-y-2">
              <p>
                Digital Animals NFTs are keys to perks within the Souls.club
                ecosystem.
              </p>
              <p>
                More available on{' '}
                <a
                  href="https://opensea.io/collection/di-animals"
                  target="_blank"
                  rel="noreferrer"
                  className="text-lightgrey underline hover:text-lightgrey/80 transition-colors"
                >
                  OpenSea
                </a>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 2xl:grid-cols-3 gap-2 sm:gap-3 md:gap-5">
            {tokens.map((token, index) => (
              <TokenItem key={`token-${index}`} token={token} />
            ))}
          </div>
        </>
      )}

      {animals.length > 0 && (
        <>
          <div className="mt-16 mb-5">
            <div className="text-[32px] font-HelveticaDisplay font-extrabold text-lightgrey mb-2">
              Metaverse
            </div>
            <div className="text-sm text-grey max-w-xs space-y-2">
              <p>
                Digital Animals feature meticulously optimized 3D models,
                designed for seamless integration and use within the Metaverse.
              </p>
              <p>
                More available on{' '}
                <a
                  href="https://opensea.io/collection/digital-animals-models-airdrop"
                  target="_blank"
                  rel="noreferrer"
                  className="text-lightgrey underline hover:text-lightgrey/80 transition-colors"
                >
                  OpenSea
                </a>
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 2xl:grid-cols-3 gap-2 sm:gap-3 md:gap-5">
            {animals.map((animal, index) => (
              <TokenItem key={`animal-${index}`} animal={animal} />
            ))}
          </div>
        </>
      )}

      {attributes.length > 0 && (
        <>
          <div className="mt-16 mb-5">
            <div className="text-[32px] font-HelveticaDisplay font-extrabold text-lightgrey mb-2">
              Attributes
            </div>
            <div className="text-sm text-grey max-w-xs space-y-2">
              <p>
                Attributes, ranging from in-game performance rewards and
                achievements to unique art pieces and event-themed items, are
                artistic enhancements for your Digital Soul.
              </p>
              <p>
                More available on{' '}
                <a
                  href="https://opensea.io/DigitalSouls_eth/created"
                  target="_blank"
                  rel="noreferrer"
                  className="text-lightgrey underline hover:text-lightgrey/80 transition-colors"
                >
                  OpenSea
                </a>
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 2xl:grid-cols-3 gap-2 sm:gap-3 md:gap-5">
            {attributes.map((attribute, index) => (
              <TokenItem key={`attribute-${index}`} attribute={attribute} />
            ))}
          </div>
        </>
      )}
    </>
  ) : (
    tokenLoaded && <Onboarding />
  );
};

export default Main;
