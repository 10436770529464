import React from 'react';

import Page from 'templates/Page';

import Main from 'components/pages/Souls/Main';
import Sidebar from 'components/pages/Souls/Sidebar';

const Souls = () => {
  return <Page main={<Main />} sidebar={<Sidebar />} />;
};

export default Souls;
