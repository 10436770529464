import { servicesRoutes } from '@/routes';

export const DISCORD_REDIRECT_URI =
  window.location.origin + servicesRoutes.DiscordCallback.path;

export const TWITTER_REDIRECT_URI =
  window.location.origin + servicesRoutes.TwitterCallback.path;

export const APPLE_REDIRECT_URI =
  window.location.origin + servicesRoutes.AppleCallback.path;

export const GOOGLE_REDIRECT_URI =
  window.location.origin + servicesRoutes.GoogleCallback.path;
