import React from 'react';

const Legendary = () => {
  return (
    <div
      className="bg-[url:var(--bg)] bg-[#141414] bg-[position:calc(50%_+_60px)_-600px] sm:bg-[position:-610px_calc(50%_+_40px)] 2xl:bg-[position:-490px_calc(50%_+_40px)] bg-no-repeat bg-[size:1000px_auto] sm:bg-[size:800px_auto] pt-56 pb-5 sm:pl-32 2xl:pl-64 sm:py-10 px-5 sm:pr-5 2xl:pr-14"
      style={{
        '--bg': `url(${require('@/assets/images/game/legendary.webp')})`,
      }}
    >
      <div className="space-y-5">
        <div className="text-4xl text-lightgrey font-HelveticaDisplay font-extrabold">
          Legendary Animal
        </div>
        <div className="text-grey text-sm">
          To get a Legendary Animal player need to burn a sufficient number of
          crystals in the pool within a specific timeframe. After completing
          first steps, players can claim their token and prepare for the reveal.
        </div>
        <div className="flex justify-between">
          <div className="text-3xl text-lightgrey font-HelveticaDisplay font-extrabold">
            Pool filling
          </div>
          <div className="text-right">
            <div className="text-[10px] text-grey">Next pool refresh:</div>
            <div className="text-lg text-lightgrey font-bold leading-none">
              -- <span className="text-sm">days</span> --:--:--
            </div>
          </div>
        </div>

        <div className="">
          <div className="relative rounded-full h-0.5 bg-[#606060] overflow-hidden">
            <span
              className="absolute let-0 top-0 h-full w-[var(--progress)] bg-green rounded-full"
              style={{ '--progress': '60%' }}
            ></span>
          </div>
          <div className="mt-1.5 text-[10px] text-grey text-center">
            ---/--- crystals
          </div>
        </div>

        <div className="">
          <button
            type="button"
            disabled
            className="flex items-center justify-center w-full px-5 py-3 bg-[#202020] rounded-[10px] text-sm text-grey font-HelveticaDisplay font-extrabold"
          >
            You need more crystals
          </button>
        </div>
      </div>
    </div>
  );
};

export default Legendary;
