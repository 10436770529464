import React, { useState, useMemo, useCallback } from 'react';

import SvgIcon from 'components/ui/SvgIcon';

import Button from 'components/ui/Button';
import Input from 'components/ui/Input';

const EmailForm = ({
  title,
  email = '',
  error = null,
  onSubmitEmail,
  clearEmailError,
  backState,
}) => {
  const [_email, setEmail] = useState(email);
  const [needValidate, setNeedValidate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const validateEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+[^0-9]+$/;
    return re.test(email);
  };

  const isValid = useMemo(() => {
    if (error) return false;

    if (!needValidate) return true;

    return validateEmail(_email);
  }, [error, needValidate, _email]);

  const errorMessage = useMemo(() => {
    setIsSubmit(false);
    return error || (!isValid ? 'Check your email' : '');
  }, [error, isValid]);

  const submitAvailable = useMemo(() => {
    return isValid || needValidate;
  }, [isValid, needValidate]);

  const handleEmailChange = (value) => {
    setEmail(value);

    clearEmailError();
  };

  const submitEmail = useCallback(
    (e) => {
      e.preventDefault();
      setNeedValidate(true);

      if (isSubmit) return;

      const isValidEmail = validateEmail(_email);

      if (isValidEmail) {
        setIsSubmit(true);
        onSubmitEmail(_email);
      }
    },
    [isSubmit, _email, onSubmitEmail],
  );

  return (
    <div className="">
      {title}

      <form onSubmit={submitEmail}>
        <div>
          <div className="mb-2">
            <Input
              type="email"
              name="email"
              placeholder="Your e-mail"
              value={_email}
              error={!isValid ? errorMessage : ''}
              // onBlur={() => setNeedValidate(true)}
              onChange={handleEmailChange}
              BeforeIcon={() => <SvgIcon name="email" />}
            />
          </div>
          <Button type="submit" disabled={!submitAvailable}>
            Send code
          </Button>
        </div>
      </form>

      <div className="mb-4">
        <button
          type="button"
          onClick={backState}
          className="flex items-center justify-center text-sm leading-none text-grey transition hover:text-white"
        >
          <SvgIcon name="arrow-left" />
          Back
        </button>
      </div>
    </div>
  );
};

export default EmailForm;
