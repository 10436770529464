import React from 'react';

import DecorationGradient from 'components/ui/DecorationGradient';

const Reports = () => {
  return (
    <div>
      <div className="mb-2 text-lightgrey text-lg font-bold">Token</div>

      <div className="mt-2 grid gap-2 grid-cols-2">
        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-grey text-xs font-medium leading-none">
              Karma tokens
            </div>
            <div className="text-2xl text-lightgrey font-bold leading-none">
              -
            </div>
          </div>
        </DecorationGradient>

        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-grey text-xs font-medium leading-none">
              Bound token
            </div>
            <div className="text-2xl text-lightgrey font-bold leading-none">
              -
            </div>
          </div>
        </DecorationGradient>
      </div>
    </div>
  );
};

export default Reports;
