import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

import LogoSprite from 'assets/common/logo_sprite.png';

const framesCount = 85;

const Logo = () => {
  const [hovered, setHovered] = useState(false);
  const [duration, setDuration] = useState((framesCount / 60) * 1000);

  const frameWidth = 28;

  useEffect(() => {
    setHovered(true);

    const timer = setTimeout(() => {
      setHovered(false);
    }, duration);

    return () => clearTimeout(timer);
  }, []);

  const { x } = useSpring({
    from: { x: 0 },
    to: { x: hovered ? framesCount - 1 : 0 },
    config: { duration },
  });

  return (
    <a
      href="/"
      aria-current="page"
      className="!flex items-center sm:items-end gap-1.5 logo router-link-exact-active router-link-active"
      onMouseEnter={() => {
        setHovered(true);
        setDuration(200);
      }}
      onMouseLeave={() => {
        setHovered(false);
        setDuration(350);
      }}
    >
      <animated.div
        style={{
          backgroundImage: `url(${LogoSprite})`,
          height: `${frameWidth}px`,
          width: `${frameWidth}px`,
          backgroundPositionX: x.to((x) => `${Math.floor(x) * -frameWidth}px`),
        }}
        className="bg-no-repeat bg-[size:auto_100%]"
      ></animated.div>
      souls.club
    </a>
  );
};

export default Logo;
