import React from 'react';

import DecorationGradient from 'components/ui/DecorationGradient';
import { useSafeSoulStore } from 'store';

const Reports = () => {
  const { votes, isLoaded } = useSafeSoulStore((state) => state);

  if (!isLoaded) return <></>;

  return (
    <div>
      <div className="mb-2 text-lightgrey text-lg font-bold">Reports</div>

      <div className="mt-2 grid gap-2 grid-cols-2 grid-rows-2">
        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-green text-xs font-medium leading-none">
              Trusted
            </div>
            <div className="text-2xl text-lightgrey font-bold leading-none">
              {votes.trusted || '-'}
            </div>
          </div>
        </DecorationGradient>

        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-[#FF7138] text-xs font-medium leading-none">
              Suspicious
            </div>
            <div className="text-2xl text-lightgrey font-bold leading-none">
              {votes.suspicious || '-'}
            </div>
          </div>
        </DecorationGradient>

        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-[#D62839] text-xs font-medium leading-none">
              Scam
            </div>
            <div className="text-2xl text-lightgrey font-bold leading-none">
              {votes.scam || '-'}
            </div>
          </div>
        </DecorationGradient>

        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-[#D62839] text-xs font-medium leading-none">
              Hacked
            </div>
            <div className="text-2xl text-lightgrey font-bold leading-none">
              {votes.hacked || '-'}
            </div>
          </div>
        </DecorationGradient>
      </div>
    </div>
  );
};

export default Reports;
