import React, { lazy } from 'react';

import ProfileHeader from 'components/common/ProfileHeader';
import Connections from './Connections';
// import Settings from './Settings';
// import Quize from './Quize';
import Logout from './Logout';

const Tips = lazy(() => import('./Tips'));

const Sidebar = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <ProfileHeader />

        <Connections />

        {/* <Settings /> */}

        <Logout />
      </div>

      {/* <Quize /> */}

      <Tips />
    </>
  );
};

export default Sidebar;
