import React from 'react';

import styles from './gostore.module.scss';

import { ReactComponent as StoreLogo } from 'assets/icons/projects/store-logo.svg';
import { STORE_SITE_LINK } from 'constants/urls';

const GoStore = () => {
  return (
    <div>
      <div className={styles['go-store']}>
        <div className={styles.title}>
          <StoreLogo />
          The Store
        </div>

        <div className={styles.text}>
          Oops, no orders yet as our Store is on its way. Stay tuned for updates
          and check the link below.
        </div>
      </div>

      <div className="">
        <a href={STORE_SITE_LINK} className={styles.button}>
          Go shopping
        </a>
      </div>
    </div>
  );
};

export default GoStore;
