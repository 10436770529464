import React, { useState } from 'react';

import Top from './Live/Top';
import List from './Live/List';

import { projectStatuses, voteStatuses } from './Live/Filters/config';

const Main = () => {
  const [searchParameters, setSearchParameters] = useState({
    count: 400,
    votes: voteStatuses[0].value,
    statuses: projectStatuses[0].value,
  });

  return (
    <div className="max-w-[1100px] mx-auto bg-dark rounded-[32px] py-4 2xl:py-10 px-2 2xl:px-8">
      <div className="xl:flex items-center xl:space-x-14">
        <h1 className="mb-1 sm:mb-0 text-6xl md:leading-tight lg:text-[54px] 2xl:text-[94px] font-bold">
          Scam stream
        </h1>
        <p className="mb-2 xl:mb-0 xl:relative xl:top-2 text-lg lg:text-xl 2xl:text-[28px] text-black opacity-40 dark:text-white font-bold xl:w-[190px] leading-tight">
          Patrol's latest activity feed
        </p>
      </div>

      <div className="relative z-[2] mt-6 sm:mt-0 mb-4">
        <Top
          setSearchParameters={setSearchParameters}
          searchParameters={searchParameters}
        />
      </div>

      <div className="">
        <List params={searchParameters} />
      </div>
    </div>
  );
};

export default Main;
