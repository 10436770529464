// https://staging-main-portal.souls.club/api/points

import client from './client';

import { PORTAL_POINTS, PORTAL_INFO } from 'constants/urls';

import handleErrors from 'utils/handleErros.js';

let controllerLeaderboard = null;
let controllerCheckName = null;

export const getPortal = async () => {
  try {
    const { data } = await client(PORTAL_INFO, {
      method: 'GET',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const setHideNotification = async () => {
  try {
    return await client('/api/points/needshow', {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const setClaimed = async () => {
  try {
    return await client('/api/points/claim', {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const getLeaderboard = async (type, period) => {
  try {
    if (controllerLeaderboard) {
      controllerLeaderboard.abort('Request cancelled');
    }

    controllerLeaderboard = new AbortController();

    const { data } = await client(`${PORTAL_POINTS}/${type}?period=${period}`, {
      method: 'GET',
      baseURL: process.env.REACT_APP_PORTAL_API,
      signal: controllerLeaderboard.signal,
    });

    return data.list;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const checkAvailableTeamName = async (name) => {
  try {
    if (controllerCheckName) {
      controllerCheckName.abort('Request cancelled');
    }

    controllerCheckName = new AbortController();

    const { data } = await client(`/api/team/check/name`, {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
      signal: controllerCheckName.signal,
      data: {
        name,
      },
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const createTeam = async (formData) => {
  try {
    const { data } = await client('/api/team/create', {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const getTeam = async (slug, period) => {
  try {
    const { data } = await client(`/api/team/${slug}?period=${period}`, {
      method: 'GET',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const joinTeam = async (slug) => {
  try {
    const { data } = await client(`/api/team/${slug}/join`, {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const leaveTeam = async (slug) => {
  try {
    const { data } = await client(`/api/team/leave`, {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
};

export const confirmInviteCode = async (code) => {
  try {
    const { data } = await client(`/api/invite/account`, {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
      data: {
        code,
      },
    });

    return data;
  } catch (error) {
    console.log(error);

    handleErrors(error?.response || error);
  }
};

export const getMyReferalsInfo = async () => {
  try {
    const { data } = await client(`/api/referrals/list`, {
      method: 'GET',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
}

export const claimMyReferals = async () => {
  try {
    const { data } = await client(`/api/referrals/claim`, {
      method: 'POST',
      baseURL: process.env.REACT_APP_PORTAL_API,
    });

    return data;
  } catch (error) {
    handleErrors(error?.response || error);
  }
}
