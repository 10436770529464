import React from 'react';

import Decoration from 'components/ui/Decoration';

import styles from './emptyblock.module.scss';

const Empty = ({ onClick, children }) => {
  return (
    <Decoration>
      <button
        type="button"
        className={styles['empty-wrapper']}
        onClick={onClick}
      >
        <div className={styles.icon}>{children}</div>
      </button>
    </Decoration>
  );
};

export default Empty;
