import React, { useState, Fragment } from 'react';
import classnames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';

import styles from './achievements.module.scss';

import { ReactComponent as ClubLogo } from 'assets/icons/projects/club-label.svg';

import { useSafeSoulStore } from 'store';
import Modal from './Modal';

const Achievements = () => {
  const [openAchievement, setOpenAchievement] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const { achievements, isLoaded } = useSafeSoulStore((state) => state);

  if (!isLoaded) return <></>;

  return (
    <div>
      <div className="mb-2 text-lightgrey text-lg font-bold">Achievements</div>
      <ul className={styles.list}>
        {achievements.map((item) => (
          <li key={item.id}>
            <div
              className={styles.container}
              onClick={() => {
                setOpenAchievement(item);
                setIsOpened(true);
              }}
            >
              <img src={item.dark.image} alt="" />
            </div>
          </li>
        ))}

        <li>
          <div className={styles['special-badge']}>
            <ClubLogo />
            <div className={classnames(styles.container, styles.special)}>
              <span>Special badge</span>
            </div>
          </div>
        </li>
      </ul>

      <Transition show={isOpened} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            setIsOpened(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/5 backdrop-blur-xl"
              aria-hidden="true"
            />
          </Transition.Child>

          <div className="fixed inset-0 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel>
                  <Modal
                    achievement={openAchievement}
                    onClose={() => setIsOpened(false)}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Achievements;
