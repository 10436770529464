import React from 'react';

import LightDecotation from 'components/ui/LightDecoration';

import styles from './animal.module.scss';

const Animal = () => {
  return (
    <LightDecotation hasLabel padding={0}>
      <picture>
        <source
          srcSet={require('assets/images/collections/animal.webp')}
          type="image/webp"
        />
        <source
          srcSet={require('assets/images/collections/animal.jpg')}
          type="image/png"
        />
        <img
          src={require('assets/images/collections/animal.jpg')}
          alt=""
          className="rounded-2xl overflow-hidden"
        />
      </picture>
    </LightDecotation>
  );
};

export default Animal;
