import { axios } from './client';
import handleErrors from '@/utils/handleErros.js';

export const getDelegateWallet = async (wallet) => {
  if (!wallet) return null;

  try {
    const { data } = await axios.get(
      `https://api.delegate.xyz/registry/v2/${wallet}`,
      {
        // headers: {
        //   'X-API-KEY': 'portala8-4c04-438e-8de1-2cfd6eef29a9',
        // },
        timeout: 1000,
      },
    );

    if (!data?.length === 0) {
      return wallet;
    }

    return data.find((item) => item.type === 'ALL')?.from || wallet;
  } catch (error) {
    console.log('Delegate error: ', handleErrors(error?.response || error));

    return wallet;
  }
};
