import { devtools } from 'zustand/middleware';
import { create } from 'zustand';

import { pushEventGTM } from 'utils/gtm';

import defaultOptions from './defaultOptions';

import { getProfile as fetchProfile } from '@/api/user';
import { logout } from '@/api/auth';
import { getDelegateWallet } from '@/api/services';
import { setTokens, isAuthenticated, clearTokens } from '@/utils/auth';

import { createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains';

const client = createPublicClient({
  chain: mainnet,
  transport: http(),
});

const defaultDummyUser = (user) => ({
  ...user,
  // twitter: {
  //   user_name: 'test',
  //   profile_image_url:
  //     'https://pbs.twimg.com/profile_images/1354479643882004483/Btnfm47p_normal.jpg',
  // },
  // wallet_address: null,
  // wallet_address: '0x29b9217d5b045f04F58F641E8EdBd8bEfcc6149c',
  // wallet_address: '0xa4b8decbe1a2d8600dc5346c7e3be5163a7d984a',
  // wallet_address: '0x2f7c1642030cba08e3586c54cd5bc31bbfde7fb3',
  // wallet_address: '0xb3889da1a8a94c0652de476a82ae0c00b00e3620',
  // wallet_address: '0x13518c0d02da15a9bd98aae85a0bd01801408791',
  // wallet_address: '0x9f3051Ccb829F3452170D42A683C4d765DACFE32',
  // wallet_address: '0x66e1fB14692dCF1Dc6ca0Ffe15d26ac8820485a6',
});

const prepareUserData = async (user) => {
  if (process.env.NODE_ENV === 'development') {
    user = defaultDummyUser(user);
  }

  let delegateWallet = user.wallet_address;

  if (!delegateWallet) return user;

  try {
    delegateWallet = await getDelegateWallet(user.wallet_address);
  } catch (error) {
    console.error(error);

    return user;
  } finally {
    try {
      const ensName = await client.getEnsName({
        address: user?.wallet_address,
      });

      user = {
        ...user,
        ensName,
        wallet_address: delegateWallet,
      };
    } catch (error) {
      console.error(error);
    }

    return user;
  }
};

const store = (set, get) => ({
  user: null,
  status: 'idle',
  isAuthenticated: isAuthenticated(),

  controls: {
    async updateUser() {
      if (!isAuthenticated()) return;

      try {
        const user = await fetchProfile();

        set({
          user: {
            ...user,
          },
        });

        prepareUserData(user).then((user) => {
          set({
            user,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getProfile() {
      if (!isAuthenticated()) {
        set({
          status: 'success',
        });

        return;
      }

      try {
        if (get().status === 'loading') return;

        set({ status: 'loading' });

        let user = await fetchProfile();

        // if (process.env.NODE_ENV === 'development') {
        //   user = defaultDummyUser(user);
        // }

        pushEventGTM({
          'event': 'user_status',
          'user_id': user.uuid,
        });

        set({
          user,
          status: 'success',
        });

        prepareUserData(user).then((user) => {
          set({
            user,
          });
        });
      } catch (error) {
        console.error(error);

        set({ status: 'error' });
      }
    },

    async auth(tokens, method = null) {

      if (method) {
        pushEventGTM({
          event: 'login',
          method,
        });
      }

      setTokens(tokens);

      set({ isAuthenticated: isAuthenticated() });

      await get().controls.getProfile();
    },

    async logout() {
      try {
        await logout();
      } catch (error) {
        console.error(error);
      } finally {
        clearTokens();

        set({ user: null, isAuthenticated: false });
      }
    },

    setUser(user) {
      set({ user });

      set({ isAuthenticated: isAuthenticated() });
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | User Date Store',
};

const useUserStore = create(devtools(store, options));

export default useUserStore;
