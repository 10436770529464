import React from 'react';

const FOOTER_URL = `${process.env.REACT_APP_GLOBAL_FOOTER_URL}/assets/index.js`;

const connectFooter = () => {
  if (document.querySelector(`script[src="${FOOTER_URL}"]`)) return;

  if (document.querySelector('souls-footer')) {
    document.querySelector('souls-footer').setAttribute('theme', 'dark');
  }

  const script = document.createElement('script');
  script.src = FOOTER_URL;
  script.async = true;

  document.body.appendChild(script);
};

const Footer = () => {
  connectFooter();

  return (
    <div className="bg-sblack">
      <div className="fluid-container">
        <souls-footer theme="dark"></souls-footer>
      </div>
    </div>
  );
};

export default Footer;
