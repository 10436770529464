import React from 'react';
import Decoration from 'components/ui/Decoration';
import SvgIcon from 'components/ui/SvgIcon';

const Marketplaces = () => {
  return (
    <Decoration>
      <div className="p-4">
        <div className="mb-3 text-sm text-lightgrey font-bold">
          Hunt for passes
        </div>
        <ul className="flex gap-4">
          <li>
            <a
              href="https://opensea.io/collection/digital-animals-soul-passes"
              target="_blank"
              rel="noreferrer"
              className="block text-lightgrey hover:text-white transition-colors"
            >
              <SvgIcon name="opensea" />
            </a>
          </li>
          <li>
            <a
              href="https://looksrare.org/ru/collections/0x3491eaD95dE2f4a945c2eaD3c3AfE7747f2Ae373"
              target="_blank"
              rel="noreferrer"
              className="block text-lightgrey hover:text-white transition-colors"
            >
              <SvgIcon name="looksrare" />
            </a>
          </li>
          <li>
            <a
              href="https://rarible.com/digital-animals-soul-passes/items"
              target="_blank"
              rel="noreferrer"
              className="block text-lightgrey hover:text-white transition-colors"
            >
              <SvgIcon name="rarible" />
            </a>
          </li>
        </ul>
      </div>
    </Decoration>
  );
};

export default Marketplaces;
