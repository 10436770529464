import React from 'react';

const SvgIcon = ({ name, size, width = null, height = null, ...props }) => {
  return (
    <svg
      style={{
        display: 'inline-block',
        fill: 'none',
        height: !height ? `${size || 24}px` : `${height}px`,
        width: `${size || width || 24}px`,
      }}
      {...props}
    >
      <use xlinkHref={`#${name}`} />
    </svg>
  );
};

export default SvgIcon;
