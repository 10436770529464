import React from 'react';

import ProfileHeader from 'components/common/ProfileHeader';
import Connect from './Connect';
import LightDecotation from 'components/ui/LightDecoration';

import Download from './Download';
import Technology from './Technology';

const Sidebar = () => {
  return (
    <>
      <div>
        <ProfileHeader />

        <Connect />

        <Download />

        <LightDecotation hasLabel>
          <div>
            <span> Mint in-game attributes</span>
          </div>
          <div>
            <span>Your name etched in The Game's secret cave.</span>
          </div>
          <div>
            <span>Season 2 early access</span>
          </div>
        </LightDecotation>

        <Technology />
      </div>
    </>
  );
};

export default Sidebar;
