import TagManager from 'react-gtm-module';

const gtmId = process.env.REACT_APP_GTM_ID;

const isEnableGTM = gtmId && process.env.NODE_ENV === 'production';

export const initializeGTM = () => {
  if (!isEnableGTM) return;

  const tagManagerArgs = {
    gtmId,
  };

  try {
    TagManager.initialize(tagManagerArgs);
  } catch (error) {
    console.error('Error in initializeGTM:', error);
  }
};

export const pushEventGTM = (event) => {
  if (!isEnableGTM) return;

  try {
    TagManager.dataLayer({
      dataLayer: event
    });
  } catch (error) {
    console.error('Error in pushEventGTM:', error);
  }
};