import axios from 'axios';
import { useEffect, useCallback } from 'react';
import { ethers } from 'ethers';

import { useUserStore, useTokenStore, useDigitalSoul } from '@/store';

import animalABI from '@/assets/abi/animals';
import metaverseABI from '@/assets/abi/metaverse';

const MIX_CONTRACT_ADDRESS = '0x25593A50255Bfb30eA027f6966417b0BF780401d';
const MIX_3D_CONTRACT = '0xd0888b57f3b760129ad6b2f750e30f3f7760ade9';

let tokenStartLoad = false;

let currentWallet = null;

const provider = window.ethereum
  ? new ethers.BrowserProvider(window.ethereum)
  : new ethers.JsonRpcProvider(
      `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_TOKEN}`,
    );

const animalContract = new ethers.Contract(
  MIX_CONTRACT_ADDRESS,
  animalABI,
  provider,
);
const metaverseContract = new ethers.Contract(
  MIX_3D_CONTRACT,
  metaverseABI,
  provider,
);

const useTokensAnimal = () => {
  const {
    user: { wallet_address },
  } = useUserStore();
  const { tokens, animals, tokenLoaded, controls } = useTokenStore(
    (state) => state,
  );
  const { attributes, controls: soulControls } = useDigitalSoul(
    (state) => state,
  );

  const replaceAnimal = useCallback(
    (group) => {
      let groups = localStorage.getItem('groups');
      if (!groups) {
        groups = [];
      } else {
        groups = JSON.parse(groups);
      }

      if (!groups.includes(group.title)) {
        groups.push(group.title);
      }

      localStorage.setItem('groups', JSON.stringify(groups));

      controls.setAnimal(group);
    },
    [controls],
  );

  const addToken = useCallback(
    async (token) => {
      let groupName = token.animalTitle.toLowerCase();

      let group = localStorage.getItem(`group_${groupName}`);

      if (group) {
        group = JSON.parse(group);

        let existingToken = group.items.find((item) => {
          return parseInt(item.id) === parseInt(token.blockChainTokenID);
        });

        if (existingToken === undefined) {
          group.count++;
          group.items.push({
            id: parseInt(token.blockChainTokenID),
            minted: await metaverseContract.isClaimed(
              parseInt(token.blockChainTokenID),
            ),
          });

          localStorage.setItem(`group_${groupName}`, JSON.stringify(group));
        }
      } else {
        group = {
          title: groupName,
          count: 0,
          items: [
            {
              id: parseInt(token.blockChainTokenID),
              minted: await metaverseContract.isClaimed(
                token.blockChainTokenID,
              ),
            },
          ],
        };

        if (localStorage.getItem('groups')) {
          let groups = JSON.parse(localStorage.getItem('groups'));

          let existingGroup = groups.find((item) => {
            return item === groupName;
          });

          if (existingGroup === undefined) {
            groups.push(groupName);
            localStorage.setItem('groups', JSON.stringify(groups));
          }
        } else {
          let groups = [];
          groups.push(groupName);
          localStorage.setItem('groups', JSON.stringify(groups));
        }

        localStorage.setItem(`group_${groupName}`, JSON.stringify(group));
      }

      replaceAnimal(group);
    },
    [replaceAnimal],
  );

  const clearTokens = useCallback(() => {
    let range = localStorage.getItem('token_range');

    if (range) {
      range = JSON.parse(range);
    } else {
      range = [];
    }

    for (let index of range) {
      localStorage.removeItem(`token_${index}`);
    }

    localStorage.removeItem('animals');
    localStorage.removeItem('range');

    let groups = localStorage.getItem('groups');

    if (groups) {
      groups = JSON.parse(groups);
    } else {
      groups = [];
    }

    for (let group of groups) {
      localStorage.removeItem(`group_${group}`);
    }

    let tokens = localStorage.getItem('tokens');
    if (tokens) {
      tokens = JSON.parse(tokens);
    } else {
      tokens = [];
    }

    for (let token of tokens) {
      localStorage.removeItem(`token_g_${token}`);
    }

    localStorage.removeItem(`groups`);
    localStorage.removeItem(`tokens`);

    controls.removeTokens(null);
  }, [controls]);

  const saveTokenToLocalStorage = useCallback(
    async (tokenID, index) => {
      let tokenKEY = `token_${index}`;
      let tokenKEYGlobal = `token_g_${tokenID}`;

      let url = await animalContract.tokenURI(parseInt(tokenID));

      url = url.replace(
        'https://digitalanimals.club',
        process.env.REACT_APP_DA_URL,
      );

      const { data } = await axios(url);

      if (!data) return;

      let token = data;
      let urlArray = url.split('/');

      token.id = parseInt(urlArray[urlArray.length - 1].replace('.json', ''));

      let titleTrait = token.attributes.find((item) => {
        return item['trait_type'] === 'Animal';
      });

      let handmadeTrait = token.attributes.find((item) => {
        return item['trait_type'] === 'Handmade';
      });

      token.blockChainTokenID = parseInt(tokenID);

      const originalMinter = await animalContract.originalMinter(tokenID);

      token.minted =
        originalMinter.toLowerCase() === wallet_address.toLowerCase();

      if (titleTrait) {
        token.animalTitle = titleTrait.value;
      }

      if (handmadeTrait) {
        token.animalTitle = `Handmade #${tokenID}`;
      }

      localStorage.setItem(tokenKEY, JSON.stringify(token));
      localStorage.setItem(tokenKEYGlobal, JSON.stringify(token));

      let tokens = localStorage.getItem('tokens');

      if (tokens) {
        tokens = JSON.parse(tokens);
      } else {
        tokens = [];
      }

      if (!tokens.includes(token.blockChainTokenID)) {
        tokens.push(token.blockChainTokenID);
      }

      localStorage.setItem('tokens', JSON.stringify(tokens));

      return token;
    },
    [wallet_address],
  );

  const getAttributes = useCallback(async () => {
    if (!wallet_address) return [];

    const collections = [
      '0xd28f9f4892ec0202a1a93491a4fdac28a6db2981', // legacy
      '0xf8f207ad1dbc71f218c952eb536bdb533d36fa65', // flex
      '0xe55d4776e226f014f566bccd053d34306e20a60f', // Excerpt
      '0xb3da835fd868ecf97764eba7739401c4bcde544b', // fam
      '0x67c04d97dc74a9f47529eeef73564c9fe1ff1933', // cube-da
    ];

    const url = `https://deep-index.moralis.io/api/v2.2/${wallet_address}/nft`;

    const { data } = await axios(url, {
      headers: {
        'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
      },
      params: {
        chain: 'eth',
        format: 'decimal',
        exclude_spam: true,
        token_addresses: collections,
        normalizeMetadata: true,
        media_items: true,
      },
    });

    if (!data.result) return [];

    return data.result;
  }, [wallet_address]);

  const loadSouls = useCallback(async () => {
    try {
      const attributes = await getAttributes();

      const result = attributes.map((item) => ({
        title: item.normalized_metadata.name,
        description: item.normalized_metadata.description,
        attributes: item.normalized_metadata.attributes,
        image: item.normalized_metadata.image,
        token_address: item.token_address,
        token_id: item.token_id,
        token_uri: item.token_uri,
        key: `${item.token_address}/${item.token_id}`,
        id: (item.token_address + item.token_id).toLowerCase(),
        multiplicator:
          Number(
            item.normalized_metadata.attributes?.find(
              (i) => i.trait_type === 'x',
            )?.value,
          ) || 1,
        amount: Number(item.amount),
      }));

      const attributesAnimation = await Promise.all(
        result.map(async (item) => {
          const { data } = await axios(item.token_uri);

          return {
            ...item,
            created_by: data.created_by,
            animation_url: data?.animation_url && data.animation_url,
          };
        }),
      );

      localStorage.setItem('attributes', JSON.stringify(attributesAnimation));

      if (currentWallet !== wallet_address) return;

      soulControls.setAttributes(attributesAnimation);
    } catch (error) {
      console.log(error);
    }
  }, [getAttributes, wallet_address, soulControls]);

  const loadTokens = useCallback(async () => {
    if (!wallet_address) {
      clearTokens();
      controls.setTokenLoaded(true);
      return;
    }

    await loadSouls();

    if (currentWallet !== wallet_address) return;

    const countAnimals = parseInt(
      await animalContract.balanceOf(wallet_address),
    );

    if (countAnimals === 0) {
      clearTokens();

      controls.setTokenLoaded(true);
      return;
    }

    let range = JSON.parse(localStorage.getItem('token_range') || '[]');

    if (range.length > 0) {
      if (range.length !== countAnimals) {
        clearTokens();
        range = Array.from(Array(countAnimals).keys());
      }
    }

    if (range.length === 0) {
      range = Array.from(Array(countAnimals).keys());
    }

    localStorage.setItem('token_range', JSON.stringify(range));

    for (let index of range) {
      let tokenKEY = `token_${index}`;

      let token = null;
      if (!localStorage.getItem(tokenKEY)) {
        let tokenID = await animalContract.tokenOfOwnerByIndex(
          wallet_address,
          index,
        );

        token = await saveTokenToLocalStorage(tokenID, index);
      } else {
        token = JSON.parse(localStorage.getItem(tokenKEY));
      }

      await addToken(token);

      if (currentWallet !== wallet_address) return;

      controls.addToken(token);
    }

    controls.setTokenLoaded(true);
  }, [
    addToken,
    clearTokens,
    controls,
    loadSouls,
    saveTokenToLocalStorage,
    wallet_address,
  ]);

  useEffect(() => {
    if (currentWallet !== wallet_address) {
      tokenStartLoad = false;
    }

    currentWallet = wallet_address;

    if (!tokenLoaded) {
      tokenStartLoad = true;
      loadTokens();
    }
  }, [controls, loadTokens, tokenLoaded, wallet_address]);

  return { tokens, tokenLoaded, attributes, animals };
};

export default useTokensAnimal;
