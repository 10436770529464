import React from 'react';

const SideBar = ({ title, description }) => {
  return (
    <div className="px-2 xl:px-0 mb-4 xl:mb-0 w-72">
      <div className="space-y-1">
        <div className="text-lightgrey text-[32px] font-HelveticaDisplay font-extrabold">
          {title}
        </div>
        <div
          className="text-grey text-sm max-w-[240px]"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
    </div>
  );
};

export default SideBar;
