import React from 'react';
import Block from './Block';

const Patrol = ({ isOg = false }) => {
  return (
    <Block className="bg-black text-white">
      {isOg ? (
        <>
          <span className="block rounded-full bg-green h-2 w-2 mr-2"></span>
          <span>OG Patrol</span>
        </>
      ) : (
        <span>Patrol</span>
      )}
    </Block>
  );
};

const Scout = () => {
  return <Block className="bg-black text-white">Scout</Block>;
};

const Role = ({ data }) => {
  const team = data.team_media_urls?.length > 0 && data.team_media_urls?.[0];

  return (
    <div className="flex items-center">
      {['og_patrol', 'patrol', 'god_mode'].includes(data.role_name) && (
        <Patrol isOg={data.role_name === 'og_patrol'} />
      )}

      {data.role_name === 'scout' && <Scout />}

      {team && (
        <div className="bg-black rounded-lg p-1 ml-0.5 w-fit flex-shrink-0">
          <img src={team} alt="" className="max-h-[16px]" />
        </div>
      )}
    </div>
  );
};

export default Role;
