import React from 'react';
import SpriteAnimation from 'components/ui/SpriteAnimation';

import CrystalSprite from 'assets/common/header_crystal.png';

const Crystal = ({ scale = 2 }) => {
  return (
    <SpriteAnimation
      url={CrystalSprite}
      scale={scale}
      height={96}
      width={140}
      frames={45}
    />
  );
};

export default Crystal;
