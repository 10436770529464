import React, { useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import SvgIcon from 'components/ui/SvgIcon';

import Input from 'components/ui/Input';
import styles from './emailInput.module.scss';

const InputEmail = ({ email = '', error, onSubmitEmail, changeState }) => {
  const [_email, setEmail] = useState(email);

  const isValid = useMemo(() => {
    if (error) return false;

    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+[^0-9]+$/;
    return re.test(_email);
  }, [error, _email]);

  const submitAvailable = useMemo(() => {
    return isValid;
  }, [isValid]);

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const submitEmail = useCallback(
    (e) => {
      e.preventDefault();

      if (!submitAvailable) return;

      if (!isValid) return;

      onSubmitEmail(_email);
    },
    [submitAvailable, isValid, onSubmitEmail, _email],
  );

  return (
    <div>
      <form onSubmit={submitEmail}>
        <div className={classnames(styles['input-email'], 'relative group')}>
          <Input
            type="email"
            name="email"
            placeholder="Your e-mail"
            value={_email}
            // error={!isValid ? errorMessage : ''}
            onChange={handleEmailChange}
            BeforeIcon={() =>
              email ? (
                <button type="button" onClick={changeState}>
                  <SvgIcon name="back" />
                </button>
              ) : (
                <SvgIcon name="email" />
              )
            }
          />

          <div
            className={classnames(
              'absolute top-1/2 -translate-y-1/2 right-4 z-10',
              !submitAvailable &&
                'opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto transition-all',
            )}
          >
            <button
              type="submit"
              disabled={!submitAvailable}
              className="w-fit bg-green text-sblack px-2 py-1 rounded-lg text-xs disabled:text-sblack/50 hover:opacity-90 transition-all disabled:pointer-events-none"
            >
              Send link
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InputEmail;
