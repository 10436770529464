import React from 'react';

import LightDecotation from 'components/ui/LightDecoration';

const Features = () => {
  return (
    <>
      <LightDecotation hasLabel>
        <div>Mint discount</div>
        <div>Extended limit per wallet</div>
        <div>Early access to new collections</div>
      </LightDecotation>

      <LightDecotation hasLabel>
        In-game delegation of NFT animals
      </LightDecotation>
    </>
  );
};

export default Features;
