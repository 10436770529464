import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { ReactComponent as LoaderSvg } from 'assets/common/souls-club-anim-logo.svg';

const Loader = () => {
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={classnames(
        'flex justify-center items-center container py-6 text-xs text-white text-center transition-opacity h-16',
        !isShow && 'opacity-0',
      )}
    >
      <LoaderSvg />
    </div>
  );
};

export default Loader;
