import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const anchor = window.location.hash;

    if (!anchor) {
      window.scrollTo(0, 0);

      return;
    }

    const el = document.querySelector(anchor);

    if (el) {
      el.scrollIntoView({
        block: 'start',
      });
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
