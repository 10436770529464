import React from 'react';

import Decoration from 'components/ui/Decoration';
import SvgIcon from '@/components/ui/SvgIcon';

const Error = ({ onClick, children, btnText = 'Back' }) => {
  return (
    <Decoration>
      <div className="px-4 py-2.5 flex items-center gap-2 text-xs text-lightgrey">
        <div className="">
          <SvgIcon name="alarm" />
        </div>

        <div className="flex-1 flex items-center min-h-[29px]">
          Error: {children}
        </div>

        <button
          type="button"
          onClick={onClick}
          className="ml-auto w-fit bg-green text-sblack px-2 py-1 rounded-lg text-xs"
        >
          {btnText}
        </button>
      </div>
    </Decoration>
  );
};

export default Error;
