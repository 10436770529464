import React, { useMemo } from 'react';

import { useWindowSize } from '@uidotdev/usehooks';
import tailwindConfig from 'utils/tailwindConfig';

import { AnimatedCounter } from "react-animated-counter";

import { usePointsStore } from '@/store';

const MAX_LENGTH_COUNTER = 9;

const AirdropTotalCounter = ({ mobileFontSize = '', desktopFontSize = '42px' }) => {
  const {
    totalCounter: { counterValue = 0 }, isCounterStarted
  } = usePointsStore();

  const { width } = useWindowSize();

  const resultValue = useMemo(() => counterValue.toString().length > MAX_LENGTH_COUNTER + 1 ? counterValue.toString().slice(0, MAX_LENGTH_COUNTER + 1) : counterValue.toString(), [counterValue]);

  const decimalPrecision = useMemo(() => {
    const parts = resultValue.split(/[\.,]/);
    return parts[1] ? parts[1].length : 0;
  }, [resultValue]);

  const fontSize = useMemo(() => {
    if (width > parseInt(tailwindConfig.theme.screens.md)) return desktopFontSize;

    return mobileFontSize;
  }, [desktopFontSize, mobileFontSize, width]);

  if (!isCounterStarted) return null;

  return (
    <AnimatedCounter
      key={fontSize}
      value={Number(resultValue)}
      color="white"
      includeCommas={true}
      decimalPrecision={decimalPrecision}
      fontSize={fontSize}
    />
  )
}

export default AirdropTotalCounter