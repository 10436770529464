import React, { useCallback } from 'react';

import { verify } from 'api/auth';
import useRainbowConnect from 'hooks/useRainbowConnect';

import SvgIcon from 'components/ui/SvgIcon';
import Decoration from 'components/ui/Decoration';

import Rainbow from 'assets/icons/rainbow.svg';

const RainbowConnectWallet = ({ onSignIn, onStartConnection }) => {
  const handleCallback = useCallback(
    async ({ result, connector }) => {
      onSignIn(result, connector);
    },
    [onSignIn],
  );

  const { error, isLoading, connectWallet, handleDisconnect } =
    useRainbowConnect(handleCallback, verify);

  const handleOpenConnectModal = useCallback(() => {
    if (isLoading || error) return;

    onStartConnection && onStartConnection();
    connectWallet();
  }, [connectWallet, onStartConnection, isLoading, error]);

  const handleReconnect = useCallback(async () => {
    await handleDisconnect();
  }, [handleDisconnect]);

  if (error)
    return (
      <Decoration>
        <div className="p-4 flex items-center gap-2 text-xs text-lightgrey">
          <div className="">
            <SvgIcon name="alarm" />
          </div>

          <div className="flex-1">Error: {error}</div>

          <button
            type="button"
            onClick={handleReconnect}
            className="ml-auto w-fit bg-green text-sblack px-2 py-1 rounded-lg text-xs"
          >
            Back
          </button>
        </div>
      </Decoration>
    );

  if (isLoading) {
    return (
      <Decoration>
        <div className="py-2.5 px-4 flex items-center justify-center gap-2 text-xs text-lightgrey h-14">
          <img src={Rainbow} alt="rainbow" />

          <div className="flex items-center h-7">Waiting Rainbow...</div>
        </div>
      </Decoration>
    );
  }

  return (
    <Decoration>
      <button
        type="button"
        className="relative rounded-2xl flex justify-center items-center p-0 overflow-hidden bg-dark h-14 w-full text-[#A2A2A2] transform-gpu grayscale transition hover:text-lightgrey hover:grayscale-0 gap-2 focus-within:outline-none"
        onClick={handleOpenConnectModal}
      >
        <img src={Rainbow} alt="rainbow" />
        <div className="text-xs">Log in with wallet</div>
      </button>
    </Decoration>
  );
};

export default RainbowConnectWallet;
