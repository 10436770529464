import React from 'react';

import SideBar from './SideBar';
import Game from './Game';
import Crystals from './Crystals';
import Legendary from './Legendary';

import { DIGITAL_ANIMALS_SITE_LINK } from '@/constants/urls';

const Main = () => {
  return (
    <div className="space-y-8">
      <div className="xl:flex xl:gap-10">
        <SideBar
          title="The Game"
          description="Mobile game, meditative adventure about the journey of the soul, embodied as animals, in a utopian world governed by AI."
        />
        <div className="flex-1 w-full rounded-[32px] overflow-hidden">
          <Game />
        </div>
      </div>

      <div className="xl:flex xl:gap-10">
        <SideBar
          title="Crystals"
          description="To initiate incineration and obtain a legendary animal, first collect 10 crystals of each element."
        />
        <div className="flex-1 w-full">
          <Crystals />
        </div>
      </div>

      <div className="xl:flex xl:gap-10">
        <SideBar
          title="Legendary animals"
          description={`Limited collection of 888 animated NFT animals. <a href='${DIGITAL_ANIMALS_SITE_LINK}/legendary' class='underline'>Learn more</a>`}
        />
        <div className="flex-1 w-full rounded-[32px] overflow-hidden">
          <Legendary />
        </div>
      </div>
    </div>
  );
};

export default Main;
