import { devtools } from 'zustand/middleware';
import { create } from 'zustand';

import defaultOptions from './defaultOptions';

const store = (set, get) => ({
  settings: null,
  isLoaded: false,
  controls: {
    async getSettings(settings) {
      set({ settings });
    },

    async updateSetting(setting, value) {
      const settings = { ...get().settings, [setting]: value };

      set({ settings });
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | Settings Store',
};

const useSettingsStore = create(devtools(store, options));

export default useSettingsStore;
