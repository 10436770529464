import React, { useMemo } from 'react';

import twitterIcon from 'assets/icons/twitter.svg';

const Project = ({ data }) => {
  const project = useMemo(() => {
    if (data.project_url) {
      return data.project_url.replace(
        /^(https?:\/\/)?(www\.)?(twitter\.com\/|x\.com\/)?/i,
        '',
      );
    }

    return null;
  }, [data.project_url]);

  const imageUrl = useMemo(() => {
    if (data.top_project_media_urls.length > 0)
      return data.top_project_media_urls[0];

    if (data.project_picture) return data.project_picture;

    if (['twitter.com/', 'x.com/'].some(i => data.project_url.includes(i))) return twitterIcon;

    return null;
  }, [data]);

  return (
    <div className="flex items-center text-xl md:text-[15px]">
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={project}
          className="w-6 h-6 inline-block mr-2 shrink-0 object-cover rounded"
        />
      ) : (
        <div className="hidden sm:block w-6 h-6 mr-2 shrink-0"></div>
      )}
      <a
        href={data.project_url}
        target="_blank"
        title={project}
        className="block truncate transition hover:opacity-70"
        rel="noreferrer"
      >
        {' '}
        {project}
      </a>
    </div>
  );
};

export default Project;
