import React, { useState } from 'react';

import Decoration from 'components/ui/Decoration';

import useAuth from 'hooks/useAuth';

import styles from './logout.module.scss';

const Logout = () => {
  const [isOpened, setIsOpened] = useState(false);

  const { logout } = useAuth();

  const handleLogout = async () => {
    logout();
  };

  return (
    <div className={styles.wrapper}>
      <Decoration className={styles.buttonWrapper}>
        <button
          type="button"
          onClick={() => setIsOpened(true)}
          className="p-4 w-full h-full flex items-center justify-center text-sm text-current"
        >
          <span>Log out</span>
        </button>
      </Decoration>

      {isOpened && (
        <div className={styles.logoutModal}>
          <div className={styles.logoutInner}>
            <div className={styles.title}>
              Are you sure you want to log out?
            </div>

            <Decoration>
              <button
                type="button"
                onClick={handleLogout}
                className="p-4 w-full h-full flex items-center justify-center text-sm text-white/70 hover:text-white font-black font-HelveticaDisplay transition-colors"
              >
                Log out
              </button>
            </Decoration>

            <button
              type="button"
              className={styles.logoutCancel}
              onClick={() => setIsOpened(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logout;
