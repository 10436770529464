const content = {
  commit: {
    media: {
      image: '/passes/committed.jpg',
      video: '/passes/committed.mp4',
    },
    level: 'Level 2',
    title: 'Committed',
    description: [
      'The commited have shown their devotion and are awarded with the potential of eternal existence.',
      'This pass will provide access for Soul Relocation...',
    ],
    perks: [
      {
        title: 'Clone',
        value: '1',
      },
    ],
  },
  soulborne: {
    media: {
      image: '/passes/soulborne.jpg',
      video: '/passes/soulborne.mp4',
    },
    level: 'Level 3',
    title: 'Soulborne',
    description: [
      'Limited in availablity, The Reaper are not only Soulborne but have mastered the art of soul aquisition and seek further ascension. This pass will provide Soulborne benefits + access for Soul linking.',
    ],
    perks: [
      {
        title: 'Clone',
        value: '10',
      },
      {
        title: 'Merge',
        value: '1',
      },
    ],
  },
  soulreaper: {
    media: {
      image: '/passes/soulreaper.jpg',
      video: '/passes/soulreaper.mp4',
    },
    level: 'Level 4',
    title: 'Soul Reaper',
    description: [
      'Limited in availablity, The Soulborne are not only commited but have understood the benefit of acquiring others souls - they have been reborn and begun the harvest. This pass will provide Commited benefits + access for merging of souls.',
    ],
    perks: [
      {
        title: 'Clone',
        value: '15',
      },
      {
        title: 'Merge',
        value: '5',
      },
      {
        title: 'Link',
        value: '5',
      },
    ],
  },
  lord: {
    media: {
      image: '/passes/lord.jpg',
      video: '/passes/lord.mp4',
    },
    level: 'Level 5',
    title: 'Lord of the Reapers',
    description: [
      'Limited in availability, Only a very view fully ascended are named the Lords of the Reapers. These are the highest awarded few of the Prologue. This journey is only just beginning. This pass will provide Soul Reapers benefits + a personally crafted founders Soul.',
    ],
    perks: [
      {
        title: 'Clone',
        value: '20',
      },
      {
        title: 'Merge',
        value: '10',
      },
      {
        title: 'Link',
        value: '10',
      },
    ],
  },
};

export default content;
