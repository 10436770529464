import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import defaultOptions from './defaultOptions';

const store = (set, get) => ({
  passesInfo: null,
  passesLoaded: false,

  controls: {
    setUserPassesInfo: (passesInfo) => {
      set({ passesInfo });
    },

    setPassesLoaded: (passesLoaded) => {
      set({ passesLoaded });
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | Soul Passes Store',
};

const useSoulPassStore = create(devtools(store, options));

export default useSoulPassStore;
