import React from 'react';

const Game = () => {
  return (
    <video
      poster={require('@/assets/onboarding/game.jpg')}
      autoPlay
      muted
      loop
      playsInline
      className="w-full aspect-video object-cover"
    >
      <source src={require('@/assets/onboarding/game.mp4')} type="video/mp4" />
    </video>
  );
};

export default Game;
