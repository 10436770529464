import React from 'react';
import { Link } from 'react-router-dom';

const UserAccount = ({ user }) => {
  return (
    <Link to={'/'} className="socials-link">
      {!user?.twitter?.profile_image_url ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          className="socials-icon"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.355 14.262V9.688c0-1.538.819-2.96 2.148-3.735l4.322-2.515a4.323 4.323 0 0 1 4.348 0l4.321 2.515a4.322 4.322 0 0 1 2.149 3.735v4.574c0 1.538-.819 2.96-2.148 3.735l-4.322 2.515a4.323 4.323 0 0 1-4.347 0l-4.322-2.515a4.321 4.321 0 0 1-2.149-3.735ZM12 8.882a1.384 1.384 0 1 0 0 2.77 1.384 1.384 0 0 0 0-2.77Zm0-1.5a2.884 2.884 0 1 0 0 5.77 2.884 2.884 0 0 0 0-5.77Zm-3.842 7.144a2.777 2.777 0 0 1 1.887-.74h3.913c.703 0 1.374.266 1.887.74.303.28.542.626.695 1.013a.75.75 0 1 1-1.395.552 1.275 1.275 0 0 0-1.187-.806h-3.913c-.322 0-.63.122-.869.342l-.51-.55.509.551a1.275 1.275 0 0 0-.32.465.75.75 0 1 1-1.392-.556c.153-.383.39-.73.695-1.011Z"
            clipRule="evenodd"
          ></path>
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="mask">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M.63 14.981V8.966a5.686 5.686 0 0 1 2.825-4.913L9.139.745a5.686 5.686 0 0 1 5.719 0l5.684 3.308a5.684 5.684 0 0 1 2.826 4.913v6.015a5.686 5.686 0 0 1-2.824 4.913l-5.685 3.309a5.686 5.686 0 0 1-5.719 0l-5.684-3.309A5.684 5.684 0 0 1 .63 14.981Z"
              fill="#fff"
            />
          </mask>
          <image
            xlinkHref={user?.twitter?.profile_image_url}
            x="0"
            y="0"
            width="24"
            height="24"
            mask="url(#mask)"
          />
        </svg>
      )}
    </Link>
  );
}

export default UserAccount;