import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useUserStore } from 'store';

import routes from 'routes';

const links = [
  'ID',
  'Souls',
  'Game',
  'SafeSoul',
  'Collection',
  'Passes',
  'Orders',
];

const PAGE_TO_SHOW_SUBHEADER = [...links, 'Digital Soul'];

const enableSubheader = Object.entries(routes)
  .filter(([key, value]) => PAGE_TO_SHOW_SUBHEADER.includes(key))
  .map(([key, value]) => value.path);

const Subheader = () => {
  const { isAuthenticated } = useUserStore();

  const location = useLocation();

  const isShowSubheader = useMemo(() => {
    const currentPath =
      location.pathname !== '/'
        ? location.pathname.replace(/\/$/, '')
        : location.pathname;

    return enableSubheader.includes(currentPath);
  }, [location]);

  if (!isShowSubheader) return null;

  return (
    <div className="subheader pt-2">
      <div className="">
        <div className="fluid-container">
          <ul className="block-flex sm:w-fit sm:ml-auto sm:bg-[#0F0F0F]/70 sm:rounded-xl sm:px-4 py-1.5 flex gap-2">
            {links.map((link) => {
              const route = routes[link];
              return (
                <li key={link}>
                  <NavLink
                    to={route.path}
                    className={({ isActive, isPending, isTransitioning }) =>
                      [
                        !isAuthenticated
                          ? 'text-darkgrey pointer-events-none disabled'
                          : 'block text-grey px-1 py-0.5 transform-gpu',
                        isPending ? 'pending' : '',
                        isActive && isAuthenticated
                          ? 'router-link-exact-active'
                          : '',
                        isTransitioning ? 'transitioning' : '',
                        route.isDisable ? 'disabled' : '',
                      ].join(' ')
                    }
                  >
                    {route.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Subheader;
