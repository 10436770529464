import React, { useState } from 'react';

import Filters from './Filters';
import Chart from './Chart';
import Period from './Filters/Period';

import { periods } from './Filters/config';

const Top = ({ searchParameters, setSearchParameters }) => {
  const [chartPeriod, setChartPeriod] = useState(periods[0].value);

  return (
    <>
      <div className="xl:flex xl:flex-wrap">
        <div>
          <Period
            period={chartPeriod || periods[0].value}
            onChange={(value) => setChartPeriod(value)}
          />
        </div>

        <div className="mt-2 mb-6 md:mt-4 order-last bg-darklight rounded-lg px-1 py-2 md:p-4 w-full">
          <Chart params={chartPeriod} />
        </div>

        <div className="xl:ml-auto xl:pl-4">
          <Filters
            searchParameters={searchParameters}
            onSearchChange={setSearchParameters}
          />
        </div>
      </div>
    </>
  );
};

export default Top;
