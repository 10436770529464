import React from 'react';

import Page from 'templates/Page';

import Main from 'components/pages/Collection/Main';
import Sidebar from 'components/pages/Collection/Sidebar';

const Collection = () => {
  return <Page main={<Main />} sidebar={<Sidebar />} />;
};

export default Collection;
