import React from 'react';
import styles from './processing.module.scss';

const Processing = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#C2E900" />
          <circle cx="24.0022" cy="24.0002" r="19.26" fill="#C2E900" />
          <circle
            cx="24.0022"
            cy="24.0002"
            r="19.26"
            fill="black"
            fillOpacity="0.33"
          />
          <circle cx="23.9977" cy="24.0025" r="14.4" fill="#C2E900" />
          <circle
            cx="23.9977"
            cy="24.0025"
            r="14.4"
            fill="black"
            fillOpacity="0.66"
          />
          <circle cx="24.0023" cy="23.9975" r="9.6" fill="#C2E900" />
          <circle
            cx="24.0023"
            cy="23.9975"
            r="9.6"
            fill="black"
            fillOpacity="0.99"
          />
          <circle cx="23.9978" cy="23.9998" r="4.74" fill="#C2E900" />
          <circle cx="23.9978" cy="23.9998" r="4.74" fill="#C2E900" />
        </svg>
      </div>

      <div className={styles.sending}>Sending...</div>

      <div className={styles.description}>{text}</div>
    </div>
  );
};

export default Processing;
