import React from 'react';
import classnames from 'classnames';

const Block = ({ children, className, ...props }) => (
  <div
    className={classnames(
      'flex items-center w-fit pt-[4px] pb-[6px] px-2 rounded-lg text-[11px] leading-none',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export default Block;
