import React from 'react';
import classnames from 'classnames';

import StatInfoDouble from 'components/common/StatInfoItemDouble';

import styles from './statistic.module.scss';

const Statistic = ({ cloned, merged, linked }) => {
  return (
    <div className={styles['flex-stats']}>
      <div>
        <StatInfoDouble
          top={
            <div className={classnames(styles['grad-top'])}>
              <div className={styles.title}>Cloned souls</div>
              <div className={styles.text}>{cloned.count}</div>
            </div>
          }
          bottom={
            <div className={styles['grad-bottom']}>
              <div>
                <span className={styles.title}>Available perks:</span>{' '}
                <span className={styles.text}>{cloned.perks}</span>
              </div>
            </div>
          }
        />
      </div>

      <div>
        <StatInfoDouble
          top={
            <div className={classnames(styles['grad-top'])}>
              <div className={styles.title}>Merged souls</div>
              <div className={styles.text}>{merged.count}</div>
            </div>
          }
          bottom={
            <div className={styles['grad-bottom']}>
              <div>
                <span className={styles.title}>Available perks:</span>{' '}
                <span className={styles.text}>{merged.perks}</span>
              </div>
            </div>
          }
        />
      </div>

      <div>
        <StatInfoDouble
          top={
            <div className={classnames(styles['grad-top'])}>
              <div className={styles.title}>Linked souls</div>
              <div className={styles.text}>{linked.count}</div>
            </div>
          }
          bottom={
            <div className={styles['grad-bottom']}>
              <div>
                <span className={styles.title}>Available perks:</span>{' '}
                <span className={styles.text}>{linked.perks}</span>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Statistic;
