import { useEffect } from 'react';

import { useDisconnect } from 'wagmi';

import { useUserStore, usePortalStore } from 'store';

const useAuth = () => {
  const { disconnect } = useDisconnect();

  const { controls, user, isAuthenticated } = useUserStore((state) => state);
  const { controls: controlsPortal } = usePortalStore((state) => state);

  useEffect(() => {
    if (user && isAuthenticated) {
      controlsPortal.getPortal();

      return;
    } else {
      controlsPortal.clearStore();
    }
  }, [controlsPortal, isAuthenticated, user]);

  const signIn = async (tokens, method = null) => {
    controls.auth(tokens, method);

    controlsPortal.getPortal();
  };

  const logout = async () => {
    try {
      controlsPortal.clearStore();

      controls.logout();

      disconnect();
    } catch (error) {
      console.log(error.message);
    }
  };

  return { signIn, logout };
};

export default useAuth;
