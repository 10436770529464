import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

import pairs from './pair';

const getPathImg = (url) => process.env.REACT_APP_DA_URL + url;

const getImageURL = (token, animal, attribute) => {
  if (token) return token.image;

  if (attribute) return attribute.image;

  let title = animal.title.split('#');

  if (title.length === 2) {
    let tokenID = title[1];
    switch (tokenID) {
      case '1048':
        return getPathImg(`/3d-preview/41.jpeg`);
      case '1203':
        return getPathImg(`/3d-preview/42.jpeg`);
      case '4507':
        return getPathImg(`/3d-preview/43.jpeg`);
      case '5968':
        return getPathImg(`/3d-preview/44.jpeg`);
      case '7564':
        return getPathImg(`/3d-preview/45.jpeg`);
      case '8571':
        return getPathImg(`/3d-preview/45.jpeg`);
      default:
        return null;
    }
  }

  return getPathImg(`/3d-preview/${pairs[animal.title]}.jpeg`);
};

const ucFirst = (str) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

const getTitle = (token, animal, attribute) => {
  if (token) return `#${token.blockChainTokenID}`;

  if (attribute) return attribute.title;

  return ucFirst(animal.title);
};

const getLink = (token, animal, attribute) => {
  if (token) return routes.TokenItem.goTo(token.blockChainTokenID);

  if (attribute)
    return routes.AttributeItem.goTo(
      attribute.token_address,
      attribute.token_id,
    );

  return routes.MetaverseItem.goTo(animal.title);
};

const getAvailableText = (animal) => {
  if (!animal) return false;

  const group = animal.items;

  const minted = group.filter((item) => {
    return item.minted;
  }).length;

  return minted > 0 ? `Minted (${minted})` : false;
};

const TokenItem = ({ token = null, animal = null, attribute = null }) => {
  const image = getImageURL(token, animal, attribute);

  const title = getTitle(token, animal, attribute);

  const linkTo = getLink(token, animal, attribute);

  const mintAvailableText = getAvailableText(animal);

  return (
    <Link
      to={linkTo}
      className="rounded-lg border border-[#353535] overflow-hidden hover:bg-[#0d0d0d] transition-all"
    >
      <div>
        <img
          alt={title}
          src={image}
          className="w-full aspect-square mix-blend-screen"
          loading="lazy"
        />
        <div className="flex w-full justify-between p-2 sm:p-4 text-xs sm:text-base">
          <span className="text-grey">{title}</span>

          {token?.minted && (
            <span className="bg-green text-sblack rounded pb-0.5 pt-1 px-2.5 leading-none font-medium">
              originally minted
            </span>
          )}

          {mintAvailableText && (
            <span className="bg-green text-sblack rounded pb-0.5 pt-1 px-2.5 leading-none font-medium">
              {mintAvailableText}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TokenItem;
