import axios from 'axios';

import { getAccess, getRefreshToken, setTokens, clearTokens } from 'utils/auth';

import { AUTH_REFRESH } from 'constants/urls';

export { axios };

let dateStartRefreshing = null;

export const getNewTokens = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    clearTokens();

    return;
  }

  if (dateStartRefreshing) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (!dateStartRefreshing) {
          clearInterval(interval);

          const accessToken = getAccess();

          resolve({ access_token: accessToken });
        }
      }, 100);
    });
  }

  try {
    dateStartRefreshing = Date.now();

    const { data } = await axios({
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
      url: AUTH_REFRESH,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        refresh_token: refreshToken,
      },
    });

    dateStartRefreshing = null;

    setTokens(data);

    return data;
  } catch (error) {
    console.error(error);

    clearTokens();
    window.location.reload();
  }
};

const client = axios.create();

client.interceptors.request.use(
  async (config) => {
    const accessToken = getAccess();

    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
client.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error, e) {
    const originalRequest = error.config;

    if (
      error?.response &&
      (error.response.status === 403)) {
      clearTokens();

      window.location.reload();
    }

    if (
      error?.response &&
      (error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const tokens = await getNewTokens();

        const access_token = tokens?.access_token;

        if (access_token) {
          client.defaults.headers.common['Authorization'] =
            'Bearer ' + access_token;

          return client(originalRequest);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export const clientAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default client;
