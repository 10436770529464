import React from 'react';

import Page from 'templates/Page';

import Main from 'components/pages/SafeSoul/Main';
import Sidebar from 'components/pages/SafeSoul/Sidebar';

const SafeSoul = () => {
  return <Page main={<Main />} sidebar={<Sidebar />} />;
};

export default SafeSoul;
