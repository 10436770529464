import React from 'react';

import { useUserStore } from 'store';

import { DISCORD_LINK } from 'constants/urls';

import Decoration from 'components/ui/Decoration';

import QRCode from '@/assets/images/game/qr.jpg';
import SvgIcon from '@/components/ui/SvgIcon';

const Download = () => {
  const { user } = useUserStore((state) => state);

  return (
    <>
      <div className="mb-4">
        <Decoration>
          <div className="flex items-center gap-2.5 p-3">
            <div className="h-20 w-20">
              {/* <img
                src={QRCode}
                alt=""
                className="w-full h-full object-contain overflow-hidden rounded"
              /> */}
              <div className="flex items-center justify-center h-20 w-20 object-contain overflow-hidden rounded border border-dashed border-lightgrey/60 text-2xl text-lightgrey font-bold leading-none">
                soon
              </div>
            </div>

            <div className="">
              <div className="mb-1 text-green font-bold text-2xl leading-none">
                Install iOS demo
              </div>
              <div className="text-xs leading-snug">
                Debug build on the Testflight <br />
                Available for DA NFT holders.{' '}
              </div>
            </div>
          </div>
        </Decoration>
      </div>

      {user.email ? (
        <div className="mb-4">
          <Decoration>
            <div className="px-4 py-3 flex gap-3">
              <div className="">
                <SvgIcon name="email" size="24" />
              </div>
              <div className="text-xs leading-none">
                <div className="mb-1 text-lightgrey">{user.email}</div>
                <div className="text-lightgrey/30">Your login to the game</div>
              </div>
            </div>
          </Decoration>
        </div>
      ) : null}

      <div className="">
        <a
          href={DISCORD_LINK}
          className="flex items-center justify-center gap-2 px-6 rounded-2xl py-3 bg-green text-sblack text-sm font-HelveticaDisplay font-extrabold transition-opacity hover:opacity-80"
          target="_blank"
          rel="noreferrer"
        >
          <SvgIcon name="discord" size="32" />
          <span>Join Discord to watch dev’s diary</span>
        </a>
      </div>
    </>
  );
};

export default Download;
