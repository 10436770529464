import { devtools } from 'zustand/middleware';
import { create } from 'zustand';

import { getGoogleUrl, getAppleUrl } from '@/api/auth';
import {
  getTwitterUrl,
  getDiscordUrl,
  disconnectDiscord,
  disconnectTwitter,
} from '@/api/user';
import {
  GOOGLE_REDIRECT_URI,
  TWITTER_REDIRECT_URI,
  APPLE_REDIRECT_URI,
  DISCORD_REDIRECT_URI,
} from '@/utils/stateSocials';

import openWindow from '@/utils/openWindow';

const store = (set, get) => ({
  googleAuthorizationUrl: null,
  isLoadingGoogleUrl: false,

  appleAuthorizationUrl: null,
  isLoadingAppleUrl: false,

  discordAuthorizationUrl: null,
  errorConnectionDiscord: null,
  isLoadingDiscordUrl: false,

  twitterAuthorizationUrl: null,
  errorConnectionTwitter: null,
  isLoadingTwitterUrl: false,

  async getGoogleUrl() {
    if (get().isLoadingGoogleUrl || get().googleAuthorizationUrl) return;

    try {
      set({ isLoadingGoogleUrl: true });
      const redirectUri = GOOGLE_REDIRECT_URI;

      const { authorization_url } = await getGoogleUrl(redirectUri);
      set({ googleAuthorizationUrl: authorization_url });
    } catch (error) {
      console.error(error);

      window.sessionStorage.removeItem('redirect-on-google-connect');
    } finally {
      set({ isLoadingGoogleUrl: false });
    }
  },

  async getAppleUrl() {
    if (get().isLoadingAppleUrl || get().appleAuthorizationUrl) return;

    try {
      set({ isLoadingAppleUrl: true });
      const redirectUri = APPLE_REDIRECT_URI;

      const { authorization_url } = await getAppleUrl(redirectUri);
      set({ appleAuthorizationUrl: authorization_url });
    } catch (error) {
      console.error(error);

      window.sessionStorage.removeItem('redirect-on-apple-connect');
    } finally {
      set({ isLoadingAppleUrl: false });
    }
  },

  async getDiscordUrl() {
    if (get().isLoadingDiscordUrl || get().discordAuthorizationUrl) return;

    try {
      set({ isLoadingDiscordUrl: true });
      const redirectUri = DISCORD_REDIRECT_URI;

      const { authorization_url } = await getDiscordUrl(redirectUri);
      set({ discordAuthorizationUrl: authorization_url });
    } catch (error) {
      console.error(error);
      window.sessionStorage.removeItem('redirect-on-discord-connect');
      set({ errorConnectionDiscord: error.message });
    } finally {
      set({ isLoadingDiscordUrl: false });
    }
  },

  async getTwitterUrl() {
    if (get().isLoadingTwitterUrl || get().twitterAuthorizationUrl) return;

    try {
      set({ isLoadingTwitterUrl: true });
      const redirectUri = TWITTER_REDIRECT_URI;

      const { authorization_url } = await getTwitterUrl(redirectUri);
      set({ twitterAuthorizationUrl: authorization_url });

      set({ errorConnectionTwitter: null });
    } catch (error) {
      console.error(error);
      window.sessionStorage.removeItem('redirect-on-twitter-connect');
      set({ errorConnectionTwitter: error.message });
    } finally {
      set({ isLoadingTwitterUrl: false });
    }
  },

  async disconnectDiscord() {
    try {
      await disconnectDiscord();

      set({ discordAuthorizationUrl: null });
    } catch (error) {
      console.error(error);
      set({ errorConnectionDiscord: error.message });
    }
  },

  resetDiscrodError() {
    set({ errorConnectionDiscord: null });
  },

  resetTwitterError() {
    set({ errorConnectionTwitter: null });
  },

  async disconnectTwitter() {
    try {
      await disconnectTwitter();

      set({ twitterAuthorizationUrl: null });
    } catch (error) {
      console.error(error);
      set({ errorConnectionTwitter: error.message });
    }
  },

  async connectGoogle() {
    try {
      if (!get().googleAuthorizationUrl) {
        await get().getGoogleUrl();
      }

      get().googleAuthorizationUrl &&
        window.sessionStorage.setItem(
          'redirect-on-google-connect',
          window.location.pathname,
        );

      openWindow({
        url: get().googleAuthorizationUrl,
      });

      set({ googleAuthorizationUrl: null });
    } catch (error) {
      console.error(error);
    }
  },

  async connectApple() {
    if (!get().appleAuthorizationUrl) {
      await get().getAppleUrl();
    }

    get().appleAuthorizationUrl &&
      window.sessionStorage.setItem(
        'redirect-on-apple-connect',
        window.location.pathname,
      );

    openWindow({
      url: get().appleAuthorizationUrl,
    });

    set({ googleAuthorizationUrl: null });
  },

  async connectDiscord() {
    try {
      if (!get().discordAuthorizationUrl) {
        await get().getDiscordUrl();
      }

      get().discordAuthorizationUrl &&
        window.sessionStorage.setItem(
          'redirect-on-discord-connect',
          window.location.pathname,
        );

      openWindow({
        url: get().discordAuthorizationUrl,
      });

      set({ discordAuthorizationUrl: null });
    } catch (error) {
      console.error(error);
    }
  },

  async connectTwitter() {
    try {
      if (!get().twitterAuthorizationUrl) {
        await get().getTwitterUrl();
      }

      get().twitterAuthorizationUrl &&
        window.sessionStorage.setItem(
          'redirect-on-twitter-connect',
          window.location.pathname,
        );

      openWindow({
        url: get().twitterAuthorizationUrl,
      });

      set({ twitterAuthorizationUrl: null });
    } catch (error) {
      console.error(error);
    }
  },
});

export default create(
  devtools(store, {
    name: 'SoulsClub | useSocialsConnections',
    enabled: false,
    serialize: { options: true },
  }),
);
