const pair = {
  dragon: 1,
  kong: 2,
  spider: 3,
  bull: 4,
  bear: 5,
  deer: 6,
  dog: 7,
  manta: 8,
  parrot: 9,
  shark: 10,
  squid: 11,
  cat: 12,
  owl: 13,
  elephant: 14,
  stork: 15,
  swallow: 16,
  rhinoceros: 17,
  tiger: 18,
  scorpion: 19,
  seahorse: 20,
  jellyfish: 21,
  horse: 22,
  hummingbird: 23,
  octopus: 24,
  peacock: 25,
  swan: 26,
  lizard: 27,
  dolphin: 28,
  turtle: 29,
  camel: 30,
  kangaroo: 31,
  goat: 32,
  hedgehog: 33,
  cow: 34,
  frog: 35,
  pork: 36,
  snake: 37,
  wolf: 38,
  bat: 39,
  whale: 40,
};

export default pair;
