import React, { useMemo, useCallback } from 'react';
import { useUserStore, useConnectionsStore } from 'store';

import SvgIcon from 'components/ui/SvgIcon';
import { pushEventGTM } from 'utils/gtm';

import ConnectedState from '../ConnectedState';
import Empty from '../Empty';
import ConnectionError from '../ConnectionError';

import useLocalStorageBus from '@/hooks/useLocalStorageBus';

import styles from '../Empty/emptyblock.module.scss';

const Discord = () => {
  const { message: error, clear } = useLocalStorageBus('discordError');

  const connectionsStore = useConnectionsStore();
  const { user, controls } = useUserStore((store) => store);

  const name = user?.discord?.user_name || null;
  const id = user?.discord?.id || null;

  const disconnect = async () => {
    await connectionsStore.disconnectDiscord();
    controls.updateUser();
  };

  const clearError = useCallback(() => {
    clear();
    connectionsStore.resetDiscrodError();
  }, [clear, connectionsStore]);

  const state = useMemo(() => {
    if (connectionsStore.errorConnectionDiscord || error) return 'error';
    if (id) return 'connected';

    return 'empty';
  }, [connectionsStore.errorConnectionDiscord, error, id]);

  if (state === 'connected') {
    return (
      <ConnectedState value={name || id} onDisconnect={disconnect}>
        <SvgIcon name="discord" />
      </ConnectedState>
    );
  }

  if (state === 'error') {
    return (
      <ConnectionError onClick={clearError}>
        {connectionsStore.errorConnectionDiscord || error}
      </ConnectionError>
    );
  }

  const handleConnectDiscord = () => {
    connectionsStore.connectDiscord();

    pushEventGTM({
      event: 'start_connect',
      method: 'Discord'
    });
  };

  return (
    <div>
      <Empty onClick={handleConnectDiscord}>
        <SvgIcon name="discord" />
        <div className={styles.emptyTextBlock}>
          <div className={styles.emptyTextTitle}>Connect Discord</div>
          <div className={styles.emptyText}>Get a role on our server</div>
        </div>
      </Empty>
    </div>
  );
};

export default Discord;
