import { Suspense, useLayoutEffect } from 'react';

import { useUserStore, usePortalStore } from 'store';

import { ReactComponent as SvgSprite } from 'assets/icons/sprite.svg';

import Layout from 'components/Layout';
import Loader from 'components/Loader';
import RoutesList from 'components/RoutesList';

import 'react-tippy/dist/tippy.css';

useUserStore.getState().controls.getProfile();

const Content = () => {
  const { status } = useUserStore((state) => state);
  const { controls } = usePortalStore();

  useLayoutEffect(() => {
    controls.getPortal();
    controls.startPeriodicUpdate();
  }, [controls]);

  return (
    <>
      <Layout>
        {status === 'loading' ? (
          <div className="h-screen flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <Suspense fallback={<Loader />}>
              <RoutesList />
            </Suspense>
          </>
        )}
      </Layout>
      <SvgSprite />
    </>
  );
};

export default Content;
