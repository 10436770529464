import { useEffect, useCallback } from 'react';
import { ethers } from 'ethers';

import { useUserStore, useSoulPassStore } from '@/store';

import soulPassABI from '@/assets/abi/SoulPass';
import soulPassABI_v2 from '@/assets/abi/SoulPass_v2';

// Lords only on current contract
const MIX_SOUL_PASS_CONTRACT = '0x3491eaD95dE2f4a945c2eaD3c3AfE7747f2Ae373';

const MIX_SOUL_PASS_CONTRACT_v2 = '0x09e8dc2fee2e6be7687ae44b1381dfd2e0c13465';

let startLoad = false;

const provider = window.ethereum
  ? new ethers.BrowserProvider(window.ethereum)
  : new ethers.JsonRpcProvider(
    `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_TOKEN}`,
  );

const soulsContract = new ethers.Contract(
  MIX_SOUL_PASS_CONTRACT,
  soulPassABI,
  provider,
);

const soulsContract_v2 = new ethers.Contract(
  MIX_SOUL_PASS_CONTRACT_v2,
  soulPassABI_v2,
  provider,
);

let currentWallet = null;

const useSoulPass = () => {
  const {
    user: { wallet_address },
  } = useUserStore();
  const { passesInfo, passesLoaded, controls } = useSoulPassStore();

  const loadPasses = useCallback(async () => {
    if (!wallet_address) {
      controls.setPassesLoaded(true);

      return;
    }

    currentWallet = wallet_address;

    const [
      commitPassCount_2,
      soulBornePassCount_2,
      soulReaperPassCount_2,
      commitPassCount,
      soulBornePassCount,
      soulReaperPassCount,
      lordPassAscendedCount,
      lordPassFirstCount,
      lordPassHarvesterCount,
      lordPassMomentCount,
      lordPassTruthCount,
    ] = await Promise.all([
      parseInt(await soulsContract_v2.balanceOf(wallet_address, 1)),
      parseInt(await soulsContract_v2.balanceOf(wallet_address, 2)),
      parseInt(await soulsContract_v2.balanceOf(wallet_address, 3)),
      parseInt(await soulsContract.balanceOf(wallet_address, 1)),
      parseInt(await soulsContract.balanceOf(wallet_address, 2)),
      parseInt(await soulsContract.balanceOf(wallet_address, 3)),
      parseInt(await soulsContract.balanceOf(wallet_address, 4)),
      parseInt(await soulsContract.balanceOf(wallet_address, 5)),
      parseInt(await soulsContract.balanceOf(wallet_address, 6)),
      parseInt(await soulsContract.balanceOf(wallet_address, 7)),
      parseInt(await soulsContract.balanceOf(wallet_address, 8)),
    ]);

    if (currentWallet !== wallet_address) return;

    controls.setUserPassesInfo({
      commitPass: {
        type: 'commit',
        id: 1,
        hasPass: commitPassCount > 0,
        title: 'Committed',
      },
      commitPass_v2: {
        type: 'commit',
        id: 1,
        hasPass: commitPassCount_2 > 0,
        title: 'Committed',
      },
      soulBornePass: {
        type: 'soulborne',
        id: 2,
        hasPass: soulBornePassCount > 0,
        title: 'Soulborne',
      },
      soulBornePass_v2: {
        type: 'soulborne',
        id: 2,
        hasPass: soulBornePassCount_2 > 0,
        title: 'Soulborne',
      },
      soulReaperPass_v2: {
        type: 'soulreaper',
        id: 3,
        hasPass: soulReaperPassCount_2 > 0,
        title: 'Soul Reaper',
      },
      soulReaperPass: {
        type: 'soulreaper',
        id: 3,
        hasPass: soulReaperPassCount > 0,
        title: 'Soul Reaper',
      },
      lordPassAscended: {
        type: 'lord',
        id: 4,
        hasPass: lordPassAscendedCount > 0,
        title: 'Lord of the Reapers',
      },
      lordPassFirst: {
        type: 'lord',
        id: 5,
        hasPass: lordPassFirstCount > 0,
        title: 'Lord of the Reapers',
      },
      lordPassHarvester: {
        type: 'lord',
        id: 6,
        hasPass: lordPassHarvesterCount > 0,
        title: 'Lord of the Reapers',
      },
      lordPassMoment: {
        type: 'lord',
        id: 7,
        hasPass: lordPassMomentCount > 0,
        title: 'Lord of the Reapers',
      },
      lordPassTruth: {
        type: 'lord',
        id: 8,
        hasPass: lordPassTruthCount > 0,
        title: 'Lord of the Reapers',
      },
    });

    controls.setPassesLoaded(true);
  }, [controls, wallet_address]);

  useEffect(() => {
    if (!startLoad && !passesLoaded) {
      startLoad = true;
      loadPasses();
    }
  }, [controls, loadPasses, passesLoaded]);

  useEffect(() => {
    if (wallet_address) {
      loadPasses();
    }
  }, [wallet_address, loadPasses, passesLoaded, controls]);

  return { passesInfo, passesLoaded };
};

export default useSoulPass;
