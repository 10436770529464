import React from 'react';

import Page from 'templates/Page';

import Main from 'components/pages/Orders/Main';
import Sidebar from 'components/pages/Orders/Sidebar';

const Orders = () => {
  return <Page main={<Main />} sidebar={<Sidebar />} />;
};

export default Orders;
