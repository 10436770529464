import React from 'react';

const SpriteAnimation = ({
  url,
  height,
  width,
  frames,
  scale = 1,
  duration = frames / 30,
  isLooping = true,
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${url})`,
        '--sprite-duration': `${duration}s`,
        '--sprite-count': frames,
        '--sprite-width': `calc(${frames} * ${width}px / ${scale} * -1)`,
        '--sprite-loop': isLooping ? 'infinite' : '1',
        height: `calc(${height}px / ${scale})`,
        width: `calc(${width}px / ${scale})`,
      }}
      className="bg-no-repeat bg-[size:auto_100%] animate-sprite"
    ></div>
  );
};

export default SpriteAnimation;
