import React from 'react';

import DecorationGradient from 'components/ui/DecorationGradient';
import SvgIcon from 'components/ui/SvgIcon';

const Reports = () => {
  return (
    <div>
      <div className="mb-2 text-lightgrey text-lg font-bold">Earns</div>

      <div className="mt-2 grid gap-2 grid-cols-3">
        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-grey text-xs font-medium leading-none">
              Ads
            </div>
            <div className="flex items-center gap-0.5 text-2xl text-lightgrey font-bold leading-none">
              <SvgIcon name="ether" size="16" />-
            </div>
          </div>
        </DecorationGradient>

        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-grey text-xs font-medium leading-none">
              Verifications
            </div>
            <div className="flex items-center gap-0.5 text-2xl text-lightgrey font-bold leading-none">
              <SvgIcon name="ether" size="16" />-
            </div>
          </div>
        </DecorationGradient>

        <DecorationGradient>
          <div className="px-3 py-2">
            <div className="mb-2 text-grey text-xs font-medium leading-none">
              Tokens
            </div>
            <div className="flex items-center gap-0.5 text-2xl text-lightgrey font-bold leading-none">
              <SvgIcon name="ether" size="16" />-
            </div>
          </div>
        </DecorationGradient>
      </div>
    </div>
  );
};

export default Reports;
