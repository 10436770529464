import React, { useMemo } from 'react';

import Item from './Item';
import PersonSvg from './PersonSvg';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const ItemSection = ({ data }) => {
  const day = useMemo(() => {
    const date = new Date(data.key);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }, [data]);

  const ordersList = useMemo(() => {
    return data.data.sort(
      (a, b) => new Date(b.voted_at).getTime() - new Date(a.voted_at).getTime(),
    );
  }, [data]);

  return (
    <div className="">
      <div className="sticky top-[47px] xl:top-[159px] z-1">
        <div className="flex items-center px-3 pt-4 pb-2 bg-dark">
          <div className="text-[22px] font-bold leading-none">{day}</div>

          <div className="flex text-white/70 text-xs font-bold ml-2">
            <PersonSvg className="w-4 h-4 mr-1" />
            {ordersList.length}
          </div>
        </div>
      </div>

      <div className="space-y-2 md:space-y-0">
        {ordersList.map((item, index) => (
          <Item
            key={item.voted_at + item.wallet + item.project_url + index}
            data={item}
          />
        ))}
      </div>
    </div>
  );
};

export default ItemSection;
