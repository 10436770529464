import React, { useMemo, Suspense, useState, useEffect, useRef } from 'react';

const ImageComponent = ({ src, loader, unloaded, alt, ...props }) => {
  const [state, setState] = useState('idle');
  const imgRef = useRef(true);

  useEffect(() => {
    return () => {
      setState('idle');
      imgRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!src || !imgRef.current) return;

    setState('loading');

    const img = new Image();

    img.onload = () => {
      if (img.width > 0 && img.height > 0) {
        imgRef.current && setState('loaded');
      } else {
        imgRef.current && setState('error');
      }
    };

    img.onerror = () => {
      imgRef.current && setState('error');
    };

    img.onabort = () => {
      imgRef.current && setState('idle');
    };

    img.src = src;
  }, [src]);

  const renderContent = useMemo(() => {
    if (src === null) return null;

    if (state === 'idle' || state === 'loading') return loader;

    if (state === 'error') return unloaded;

    return <img src={src} alt={alt} {...props} decoding='' />;
  }, [src, state, loader, unloaded, alt, props]);

  return <Suspense fallback={loader}>{renderContent}</Suspense>;
};

export default ImageComponent;
