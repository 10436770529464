import { Navigate, Outlet } from 'react-router-dom';

import routes from 'routes';

const ProtectedRoute = ({
  isAllowed,
  redirectPath = routes.ID.path,
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
