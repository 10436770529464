import React, { useEffect, useState } from 'react';

const User = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasAvatar, setHasAvatar] = useState(false);

  const wallet = data.output_wallet;
  const hasTwitter = data.has_twitter;

  const checkImage = (src) => {
    if (!src) return false;

    const img = new Image();

    img.onload = () => {
      setHasAvatar(true);
      setIsLoaded(true);
    };
    img.onerror = () => {
      setHasAvatar(false);
      setIsLoaded(true);
    };

    img.src = data.twitter_avatar;
  };

  checkImage(data?.twitter_avatar);

  return (
    <div className="flex items-center">
      <div className="px-1 py-1.5 rounded-lg md:rounded-none bg-white/10 md:p-0 md:bg-transparent text-[11px] md:w-[72px] shrink-0">
        {wallet}
      </div>
      {hasTwitter && isLoaded ? (
        <div className="ml-0.2 md:ml-2 flex-shrink-0">
          {hasAvatar ? (
            <img
              className="w-6 h-6 rounded-full"
              src={data.twitter_avatar}
              alt=""
            />
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="4" fill="#57AEE8" />
              <path
                d="M20 7.03773C19.4116 7.29845 18.7795 7.47462 18.115 7.55424C18.7929 7.14836 19.313 6.5043 19.5582 5.73763C18.924 6.11392 18.2214 6.38733 17.4738 6.5339C16.8748 5.89688 16.0222 5.49805 15.078 5.49805C13.2656 5.49805 11.7956 6.96797 11.7956 8.78176C11.7956 9.03826 11.8252 9.28771 11.8802 9.5287C9.15106 9.39129 6.73337 8.08415 5.11407 6.09842C4.8322 6.58463 4.67013 7.14836 4.67013 7.74873C4.67013 8.88746 5.25007 9.89301 6.13019 10.4814C5.59183 10.4638 5.08588 10.3165 4.64335 10.0706V10.1129C4.64335 11.7033 5.77433 13.0302 7.27667 13.3317C7.00044 13.4064 6.71083 13.4459 6.41205 13.4459C6.20065 13.4459 5.99419 13.4262 5.79406 13.3881C6.21193 14.6917 7.42394 15.6416 8.86074 15.667C7.73681 16.5478 6.32115 17.0728 4.78358 17.0728C4.51863 17.0728 4.2572 17.0573 4 17.027C5.45301 17.9592 7.17801 18.5018 9.03127 18.5018C15.0702 18.5018 18.3715 13.5002 18.3715 9.16228C18.3715 9.02134 18.368 8.879 18.3617 8.73807C19.0029 8.27441 19.5596 7.69729 19.9986 7.03984L20 7.03773Z"
                fill="white"
              />
            </svg>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default User;
