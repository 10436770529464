import React, { useState } from 'react';
import classanmes from 'classnames';

import Input from 'components/ui/Input';

import styles from './control.module.scss';

const Control = ({ value, onDisconnect, children }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <div>
      {showConfirmation ? (
        <div className={styles['block-wrapper']}>
          <Input
            value="Are you sure?"
            readOnly={true}
            type="text"
            name="name"
            BeforeIcon={() => children}
          />

          <div
            className={classanmes(
              styles['disconnect-container'],
              styles['disconnect-container-confirm'],
            )}
          >
            <button
              type="button"
              onClick={onDisconnect}
              className={styles.disconnect}
            >
              Disconnect
            </button>

            <button
              type="button"
              onClick={() => setShowConfirmation(false)}
              className={styles.cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className={styles['block-wrapper']}>
          <Input
            value={value}
            readOnly={true}
            type="text"
            name="name"
            BeforeIcon={() => children}
          />

          {onDisconnect && (
            <div className={styles['disconnect-container']}>
              <button
                type="button"
                onClick={() => setShowConfirmation(true)}
                className={styles.disconnect}
              >
                Disconnect
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Control;
