import React from 'react';

const isDisabled = true;

const ItemPass = ({ media, level, title, description, perks }) => {
  return (
    <div className="rounded-[32px] border border-darkgrey bg-dark backdrop-blur-lg overflow-hidden">
      <div className="xl:flex xl:h-60">
        <div className="flex-shrink-0 bg-black">
          {media.video ? (
            <video
              src={media.video}
              poster={media.image}
              preload="auto"
              loop
              muted
              autoPlay
              playsInline
              alt=""
              className="mx-auto sm:h-full aspect-square xl:max-w-md object-cover"
            />
          ) : (
            media.image && (
              <img
                src={media.image}
                alt=""
                className="mx-auto sm:h-full aspect-square xl:max-w-md object-cover"
              />
            )
          )}
        </div>

        <div className="flex flex-col gap-2 p-7">
          <div className="text-xs leading-none text-grey">{level}</div>
          <div className="text-2xl leading-none text-lightgrey font-HelveticaDisplay font-extrabold">
            {title}
          </div>
          <div className="text-xs text-grey leading-snug">
            {description.map((i) => (
              <p key={i}>{i}</p>
            ))}
          </div>
          <div className="mt-auto">
            <div className="sm:flex flex-wrap">
              <div>
                <div className="mb-3 text-xs leading-none text-grey">
                  By burning this pass you can get perks:
                </div>
                <div className="flex gap-2">
                  {perks.map((i) => (
                    <div
                      key={i.title}
                      className="rounded-lg px-3 py-2 bg-[#0f0f0f] text-xs font-bold text-grey"
                    >
                      {i.title}: <span className="text-green">{i.value}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-3 xl:mt-0 sm:ml-auto">
                {isDisabled ? (
                  <button
                    type="button"
                    className="flex items-center justify-center w-full sm:w-40 h-10 sm:h-14 px-8 py-1.5 rounded-lg bg-[#333333] backdrop-blur text-[#B3B3B3] font-HelveticaDisplay font-extrabold text-xs leading-tight"
                    disabled
                  >
                    Burn and claim my perks <br className="hidden sm:inline" />
                    (Soon)
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex items-center justify-center w-full sm:w-40 h-10 sm:h-14 px-6 py-1.5 rounded-lg bg-green backdrop-blur text-sblack font-HelveticaDisplay font-extrabold text-xs leading-tight hover:opacity-80 transition-all"
                  >
                    Burn and claim my perks
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemPass;
