import React, { lazy } from 'react';
import { useSoulPassStore } from '@/store';

import ProfileHeader from 'components/common/ProfileHeader';
import Passes from './Passes';

const NoPass = lazy(() => import('./NoPass'));
const Loader = lazy(() => import('@/components/Loader'));

const Sidebar = () => {
  const { passesInfo, passesLoaded } = useSoulPassStore();

  return (
    <>
      <div>
        <ProfileHeader />

        {passesLoaded ? (
          passesInfo &&
          (Object.values(passesInfo).some((pass) => pass.hasPass) ? (
            <Passes />
          ) : (
            <NoPass />
          ))
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Sidebar;
