import React from 'react';

import Page from 'templates/Page';

import Main from 'components/pages/Game/Main/index.jsx';
import Sidebar from 'components/pages/Game/Sidebar';

const Game = () => {
  return <Page main={<Main />} sidebar={<Sidebar />} />;
};

export default Game;
