import React from 'react';

import styles from './switcher.module.scss';

const Switcher = ({ children, checked, onChange, ...props }) => {
  return (
    <div className={styles['switcher']}>
      {children}

      <label className={styles['switcher-label']}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={styles['switcher-controller']}
          {...props}
        />
        <span className={styles['switcher-element']}></span>
      </label>
    </div>
  );
};

export default Switcher;
