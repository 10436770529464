import React, { lazy } from 'react';

import useTokensAnimal from 'hooks/useTokensAnimal';

import ProfileHeader from 'components/common/ProfileHeader';
import Statistic from './Statistic';
import Animal from './Animal';
import Features from './Features';

const NoAnimals = lazy(() => import('./NoAnimals'));
const Loader = lazy(() => import('@/components/Loader'));

const Sidebar = () => {
  const { tokens, animals, attributes, tokenLoaded } = useTokensAnimal();

  return (
    <>
      <div>
        <ProfileHeader />
        {tokenLoaded ? (
          tokens.length === 0 ? (
            <NoAnimals />
          ) : (
            <Statistic
              animals={animals}
              tokens={tokens}
              attributes={attributes}
            />
          )
        ) : (
          <Loader />
        )}

        <Animal />

        <Features />
      </div>
    </>
  );
};

export default Sidebar;
