import React, { Suspense } from 'react';
import { Provider as BusProvider } from 'react-bus';

import Content from './templates/Content';

import { servicesRoutes } from 'routes';

const services = Object.values(servicesRoutes).find(
  ({ path }) => window.location.pathname === path,
);

const App = () => {
  if (services) {
    return <Suspense fallback={<></>}>{services.element}</Suspense>;
  }

  return (
    <BusProvider>
      <Content />
    </BusProvider>
  );
};

export default App;
