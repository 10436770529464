import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import defaultOptions from './defaultOptions';

import usePortalStore from './portal';

let timer = null;

const store = (set, get) => ({
  totalCounter: {
    counterValue: 345.00298,
    perPeriod: 0.213,
  },
  isCounterStarted: false,

  controls: {
    startTotalPointsCounter({ points, perPeriod }) {
      const UPDATE_INTERVAL = 100;

      timer && clearInterval(timer);

      set({
        totalCounter: {
          counterValue: Number(points),
          perPeriodLabel: perPeriod,
          perPeriod: perPeriod / 24 / 60 / 60 / 1000,
        }
      });

      timer = setInterval(() => {
        set({
          totalCounter: {
            ...get().totalCounter,
            counterValue: get().totalCounter.counterValue + get().totalCounter.perPeriod * UPDATE_INTERVAL,
          },
          isCounterStarted: true,
        });
      }, UPDATE_INTERVAL);
    },
    setPointsCounter({ total_points, daily_farm }) {
      get().controls.startTotalPointsCounter({
        points: total_points || 0,
        perPeriod: Number(daily_farm || 0)
      });
    }
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | Soul Points Store',
};

const usePointsStore = create(devtools(store, options));

usePortalStore.subscribe(
  ({ user }) => user,
  (user) => {
    usePointsStore.getState().controls.setPointsCounter(user);
  }
);

export default usePointsStore;
