import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useUserStore } from 'store';
import { updateEmail, confirmEmail } from '@/api/user';
import { pushEventGTM } from 'utils/gtm';

import routes from 'routes';

import EmailInput from './EmailInput';
import Empty from '../Empty';
import ConnectionError from '../ConnectionError';
import EmailOutput from './EmailOutput';

import SvgIcon from 'components/ui/SvgIcon';

import styles from '../Empty/emptyblock.module.scss';

const Email = () => {
  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const [confirmationSentEmail, setConfirmationSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { user, controls } = useUserStore((store) => store);

  const state = useMemo(() => {
    if (confirmationSentEmail) return 'confirmation-sent';
    if (error) return 'error';
    if (openInput) return 'email-input';
    if (location.pathname === routes.ConfirmLink.path)
      return 'confirmation-sent';
    if (user?.email) return 'email';
    if (user?.email === '') return 'empty';

    return 'email-input';
  }, [confirmationSentEmail, error, location.pathname, openInput, user?.email]);

  const onSubmitEmail = useCallback(
    async (email) => {
      if (!email || isProcessing) return;

      setProcessing(true);

      pushEventGTM({
        event: 'start_connect',
        method: 'Email'
      });

      try {
        await updateEmail(email);

        setConfirmationSent(email);
      } catch (error) {
        setError(error.message);
      } finally {
        setProcessing(false);
      }
    },
    [isProcessing],
  );

  const handleConfirmEmail = useCallback(async () => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const email = url.searchParams.get('email');

    setConfirmationSent(email);

    if (token && email) {
      try {
        await confirmEmail({ token, email });

        pushEventGTM({
          event: 'connect',
          method: 'Email'
        });

        controls.updateUser();

        setConfirmationSent(false);
      } catch (error) {
        setError(error.message);
      } finally {
        navigate(routes.ID.path, { replace: true });
      }
    }
  }, [controls, navigate]);

  useEffect(() => {
    if (location.pathname === routes.ConfirmLink.path) {
      handleConfirmEmail(location);
    }
  }, [handleConfirmEmail, location]);

  if (state === 'error') {
    return (
      <ConnectionError onClick={() => setError(null)}>{error}</ConnectionError>
    );
  }

  if (state === 'email') {
    return (
      <EmailOutput
        email={user?.email}
        disable={user.google_auth}
        changeState={() => setOpenInput(true)}
      />
    );
  }

  if (state === 'email-input') {
    return (
      <EmailInput
        email={user?.email ?? ''}
        onSubmitEmail={onSubmitEmail}
        changeState={() => setOpenInput(false)}
      />
    );
  }

  if (state === 'confirmation-sent') {
    return (
      <div>
        <Empty>
          <SvgIcon name="email" />
          <div className={styles.emptyTextBlock}>
            <div
              className={classnames(styles.emptyTextTitle, '!text-[#FF9900]')}
            >
              {confirmationSentEmail}
            </div>
            <div className={styles.emptyText}>
              Confirmation link was successfully sent
            </div>
          </div>
        </Empty>
      </div>
    );
  }

  return (
    <div>
      <Empty onClick={() => setOpenInput(true)}>
        <SvgIcon name="email" />
        <div className={styles.emptyTextBlock}>
          <div className={styles.emptyTextTitle}>Connect e-mail</div>
          <div className={styles.emptyText}>
            Allows you to log in with your email
          </div>
        </div>
      </Empty>
    </div>
  );
};

export default Email;
