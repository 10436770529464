import { useUserStore } from 'store';

const ACCESS_TOKEN = 'access';
const REFRESH_TOKEN = 'refresh';

export const setTokens = ({ access_token, refresh_token }) => {
  if (!access_token || !refresh_token) {
    clearTokens();

    return;
  }

  localStorage.setItem(ACCESS_TOKEN, access_token);
  localStorage.setItem(REFRESH_TOKEN, refresh_token);
};

export const getTokens = () => {
  const access_token = localStorage.getItem(ACCESS_TOKEN);
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  return { access_token, refresh_token };
};

export const clearTokens = () => {
  const { controls } = useUserStore.getState();

  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);

  controls.setUser(null);
};

export const getAccess = () => {
  const { access_token } = getTokens();

  return access_token;
};

export const getRefreshToken = () => {
  const { refresh_token } = getTokens();
  return refresh_token;
};

export const isAuthenticated = () => {
  const { access_token } = getTokens();

  return !!access_token;
};