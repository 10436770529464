import React from 'react';

import SoulStatts from '@/components/common/SoulStatts';
import Marketplaces from '../Marketplaces';

const Passes = () => {
  return (
    <div>
      <div className="mb-4">
        <SoulStatts
          cloned={{
            count: '-',
            perks: 0,
          }}
          merged={{
            count: '-',
            perks: 0,
          }}
          linked={{
            count: '-',
            perks: 0,
          }}
        />
      </div>

      <Marketplaces />
    </div>
  );
};

export default Passes;
