import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { useUserStore } from '@/store';
import Crystal from 'components/common/Crystal';

import AirdropTotalCounter from '@/components/common/AirdropTotalCounter';

const AirdropPoints = () => {
  const { width } = useWindowSize();
  const { isAuthenticated } = useUserStore();

  return (
    <div className="flex items-center bg-[#232323]/60 rounded-lg py-1 px-2 overflow-hidden group-hover:bg-[#232323]">
      <div className="h-9 flex-shrink-0 -ml-1 -mr-1">
        <div className="scale-90 origin-center -mt-1.5">
          <Crystal scale={2} />
        </div>
      </div>

      {isAuthenticated ?
        (width > 1300 &&
          <div className='ml-2 text-lg text-white font-bold flex items-center'>
            <AirdropTotalCounter mobileFontSize="12px" desktopFontSize="18px" />
          </div>
        ) :
        <div className='ml-2 text-lg text-white font-bold flex items-center'>Yes. Airdrop</div>
      }
    </div>
  )
}

export default AirdropPoints