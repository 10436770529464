import React from 'react';

import StatInfoItem from 'components/common/StatInfoItem';

import styles from './statistic.module.scss';

const Statistic = ({ tokens, animals, attributes }) => {
  return (
    <div className={styles.flex}>
      <div className={styles.item}>
        <StatInfoItem title="Animals">
          <div>{tokens.length}</div>
        </StatInfoItem>
      </div>

      <div className={styles.item}>
        <StatInfoItem title="3d models">
          <div>{animals.length}</div>
        </StatInfoItem>
      </div>

      <div className={styles.item}>
        <StatInfoItem title="Attributes">
          <div>
            {attributes.reduce((acc, item) => acc + Number(item.amount), 0)}
          </div>
        </StatInfoItem>
      </div>
    </div>
  );
};

export default Statistic;
