import React, { useState } from 'react';
import { useListener } from 'react-bus';

import Modal from '@/components/ui/Modal';
import SvgIcon from 'components/ui/SvgIcon';
import Decoration from 'components/ui/Decoration';
import XFriends from 'assets/images/auth/x-friends.png';

import { useConnectionsStore } from 'store';

const ConnectTwitter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const connectionsStore = useConnectionsStore();

  useListener('modal:connectTwitter:open', () => {
    setIsOpen(true);
  });

  useListener('modal:connectTwitter:close', () => {
    setIsOpen(false);
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="!max-w-sm"
    >
      <div className="w-full p-4 rounded-3xl bg-[#0F0F0F] relative">
        <img
          src={XFriends}
          className="absolute -top-[100px] right-[120px]"
          alt=""
        />

        <div className="px-2 pt-1 mb-4">
          <div className="mb-2 leading-none text-lightgrey text-[32px] font-HelveticaDisplay font-extrabold">
            Meet X-friends
          </div>
          <div className="text-xs text-grey leading-normal space-y-1">
            <p>
              Connected socials will highlight your friends in the leaderboard,
              make you visible for them and add your avatar and name to the
              rating.{' '}
            </p>
          </div>
        </div>

        <Decoration>
          <button
            type="button"
            className="relative rounded-2xl flex items-center px-4 overflow-hidden bg-dark h-14 w-full text-[#A2A2A2] transform-gpu grayscale transition hover:text-lightgrey hover:grayscale-0 gap-3 focus-within:outline-none"
            onClick={connectionsStore.connectTwitter}
          >
            <SvgIcon name="X" className="w-6 h-6" />
            <div className="text-xs">Connect Twitter</div>
          </button>
        </Decoration>
      </div>
    </Modal>
  );
};

export default ConnectTwitter;
