import React from 'react';

const PersonSvg = ({ className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.91602 11.6663C2.91602 10.2079 4.08268 9.09961 5.48268 9.09961H8.45768C9.91602 9.09961 11.0243 10.2663 11.0243 11.6663"
        stroke="currentColor"
        strokeWidth="1.4824"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74938 3.03301C9.74105 4.02467 9.74105 5.59967 8.74938 6.53301C7.75772 7.46634 6.18272 7.52467 5.24938 6.53301C4.31605 5.54134 4.25772 3.96634 5.24938 3.03301C6.24105 2.09967 7.75772 2.09967 8.74938 3.03301"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonSvg;
