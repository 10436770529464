import { useEffect, useCallback, useState } from 'react';

const useLocalStorageBus = (key) => {
  const [message, setMessage] = useState(null);

  const handleChangeLocalStorage = useCallback(
    (event) => {
      if (event.key === key && event.newValue) {
        setMessage(event.newValue);

        localStorage.removeItem(key);
      }
    },
    [key],
  );

  const clear = () => {
    setMessage(null);
    localStorage.removeItem(key);
  };

  useEffect(() => {
    const message = localStorage.getItem(key);

    if (message) {
      setMessage(message);

      localStorage.removeItem(key);
    }

    window.addEventListener('storage', handleChangeLocalStorage);

    return () => {
      window.removeEventListener('storage', handleChangeLocalStorage);
    };
  }, [handleChangeLocalStorage, key]);

  return {
    message,
    clear,
  };
};

export default useLocalStorageBus;
