import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Decoration = ({
  error = '',
  isInteract = false,
  className,
  children,
}) => {
  return (
    <div
      className={classnames(
        className,
        styles.containerDecor,
        isInteract && styles.interact,
        error && styles.isError,
      )}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default Decoration;
