import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';

import { getPortal, getLeaderboard, setClaimed, getMyReferalsInfo, claimMyReferals } from '@/api/portal';

import defaultOptions from './defaultOptions';

const store = (set, get) => ({
  leaderboardList: [],
  type: 'account',
  global: {
    dropInfo: {
      nextDrop: null,
      lastDrop: null,
    },
    total_teams: 0,
    total_users: 0,
    claimed: false,
  },
  user: {
    isBlocked: false,
    discord_roles: [],
    invites_count: 0,
    invited: true,
    isNeedShow: false,
    rank: 0,
    total_points: 0,
  },
  myReferal: {
    invited_me: null,
    invited: 0,
    referrals: [],
  },
  isLoaded: false,
  isLoadedList: false,
  controls: {
    startPeriodicUpdate() {
      setInterval(() => {
        get().controls.getPortal();
      }, 1000 * 60);
    },

    async getPortal() {
      try {
        const { global, user } = await getPortal();

        if (user) {
          set({ user });
        }

        set({
          global,
          isLoaded: true,
        });
      } catch (error) {
        console.error(error);
      } finally {
        set({ isLoaded: true });
      }
    },

    async getLeaderboard(type = 'account', period = 'total') {
      try {
        set({ isLoadedList: false, leaderboardList: [], type });

        const list = await getLeaderboard(type, period);

        set({
          leaderboardList: list,
          isLoadedList: true,
        });
      } catch (error) {
        console.error(error);

        set({ leaderboardList: [] });
      } finally {
        set({ isLoadedList: true });
      }
    },

    async getMyreferal() {
      const data = await getMyReferalsInfo();

      set({
        myReferal: data,
      });
    },

    async claimReferals() {
      await claimMyReferals();

      get().controls.getMyreferal();
      get().controls.getPortal();
    },

    async claimPoints() {
      await setClaimed();

      await get().controls.getPortal();
    },

    setIsNeedShow(value) {
      set({
        user: {
          ...get().user,
          isNeedShow: value
        }
      });
    },

    clearStore() {
      set({
        leaderboardList: [],
        type: 'account',
        global: {
          dropInfo: {
            nextDrop: null,
            lastDrop: null,
          },
          total_teams: 0,
          total_users: 0,
          claimed: false,
        },
        user: {
          discord_roles: [],
          invited: 0,
          isNeedShow: false,
          rank: 0,
          total_points: 0,
        },
        isLoaded: false,
        isLoadedList: false,
      });
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | Portal Store',
};

const usePortalStore = create(devtools(subscribeWithSelector(store), options));

export default usePortalStore;
