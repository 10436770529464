import React from 'react';

import styles from './technology.module.scss';

import { ReactComponent as Unreal } from 'assets/icons/unrial-engine.svg';
import { ReactComponent as Apple } from 'assets/icons/apple-store.svg';
import { ReactComponent as Google } from 'assets/icons/google-store.svg';

const Technology = () => {
  return (
    <div className={styles.links}>
      <div className={styles.unreal}>
        <Unreal />
      </div>

      <div className={styles.stores}>
        <div className={styles['apple-store']}>
          <a href="/#" className="pointer-events-none">
            <Apple />
          </a>
        </div>
        <div className={styles['google-store']}>
          <a href="/#" className="pointer-events-none">
            <Google />
          </a>
        </div>

        <div className={styles['links-text']}>Soon in stores</div>
      </div>
    </div>
  );
};

export default Technology;
