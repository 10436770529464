import React from 'react';
import classnames from 'classnames';

import './page.scss';

const Page = ({ main, sidebar, className }) => {
  return (
    <div className="fluid-container">
      <div className="page-wrapper">
        <div
          className={classnames(
            'lg:flex lg:flex-row-reverse lg:gap-2 2xl:gap-4',
            className,
          )}
        >
          <aside className="page-sidebar">
            <div className="sticky top-[100px] lg:max-h-[calc(100vh_-_96px)] lg:overflow-auto">
              {sidebar}
            </div>
          </aside>
          <main className="page-content">{main}</main>
        </div>
      </div>
    </div>
  );
};

export default Page;
