import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import SvgIcon from 'components/ui/SvgIcon';

import Button from 'components/ui/Button';
import Input from 'components/ui/Input';

import CodeVerification from './CodeVerification';

import styles from './otpcode.module.scss';

import { OTP_CODE_LENGTH } from 'constants/configs';
const TIME_TO_RESEND = 120;

const getDateSumbit = () => {
  const dateLastSend = localStorage.getItem('dateOTPSend') || null;

  if (!dateLastSend) return 0;

  const diff = (
    TIME_TO_RESEND -
    (new Date().getTime() - new Date(parseInt(dateLastSend)).getTime()) / 1000
  ).toFixed(0);

  if (diff <= 0) return 0;

  return parseInt(diff);
};

const OtpCode = ({
  email,
  error,
  onChangeState,
  sendOtp,
  resendOtp,
  clearOtpError,
}) => {
  const [otpCode, setOtpCode] = useState('');
  const [timer, setTimer] = useState(getDateSumbit());
  const [isSubmit, setIsSubmit] = useState(false);
  const submitBtn = useRef(null);

  const enableSubmit = useMemo(
    () => otpCode.length === OTP_CODE_LENGTH && !error,
    [error, otpCode.length],
  );

  const isTimerStarted = useMemo(() => timer > 0, [timer]);

  const handleSubmitOtpCode = useCallback(() => {
    if (!enableSubmit || isSubmit) {
      return;
    }

    setIsSubmit(true);

    sendOtp({ email, otp: otpCode });
  }, [enableSubmit, isSubmit, sendOtp, email, otpCode]);

  useEffect(() => {
    if (!enableSubmit) return;

    handleSubmitOtpCode();
  }, [enableSubmit, handleSubmitOtpCode]);

  useEffect(() => {
    if (!error) return;

    setIsSubmit(false);
  }, [error]);

  const handleResendOtp = useCallback(() => {
    if (isTimerStarted) return;

    resendOtp(email);
    const start = getDateSumbit();
    setTimer(start);
  }, [resendOtp, isTimerStarted, email]);

  useEffect(() => {
    if (!isTimerStarted) return;

    const interval = setInterval(() => {
      setTimer((state) => state - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerStarted]);

  useEffect(() => {
    if (!submitBtn.current || !otpCode) return;

    setTimeout(() => {
      submitBtn.current.childNodes[0].focus();
    }, 100);
  }, [submitBtn, otpCode]);

  return (
    <div>
      <div className={styles['input-wrap']}>
        <Input
          type="email"
          placeholder="Your e-mail"
          value={email}
          readOnly={true}
          BeforeIcon={() => <SvgIcon name="email" />}
        />
        <div className={styles['change-email']}>
          <button
            type="button"
            className={styles['change-email-btn']}
            onClick={() => onChangeState('email-form')}
          >
            Change
          </button>
        </div>
      </div>

      <CodeVerification
        codeLength={OTP_CODE_LENGTH}
        onInputCode={setOtpCode}
        onChangeCode={clearOtpError}
        error={error}
      />

      <div className={styles['confirm-bnt']} ref={submitBtn}>
        <Button
          type="button"
          disabled={!enableSubmit || isSubmit}
          onClick={handleSubmitOtpCode}
        >
          Done
        </Button>
      </div>

      {!error ? (
        <div className={styles['resend-code']}>
          Didn’t get the code?{' '}
          <button
            type="button"
            className={styles['resend-code-button']}
            onClick={handleResendOtp}
            disabled={isTimerStarted}
          >
            Send again
          </button>
          {isTimerStarted ? <> in {timer} sec</> : null}
        </div>
      ) : null}
    </div>
  );
};

export default OtpCode;
