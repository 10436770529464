import React from 'react';
import PersonSvg from '../PersonSvg';

const TotalVotes = ({ data: { bad_votes_count, good_votes_count } }) => {
  return (
    <div className="flex space-x-2 text-[11px]">
      <div className="flex items-center text-[#C2E900]">
        <PersonSvg className="" />
        {good_votes_count || 0}
      </div>
      {/* <div className="flex items-center text-[#FF7138]">
        <PersonSvg className="" />
        0
      </div> */}
      <div className="flex items-center text-[#D62839]">
        <PersonSvg className="" />
        {bad_votes_count || 0}
      </div>
    </div>
  );
};

export default TotalVotes;
