class ApiError extends Error {
  constructor(message, code) {
    super(message); // Вызов конструктора базового класса с сообщением об ошибке
    this.code = code;
    this.name = this.constructor.name; // Установка имени ошибки как имени класса
  }
}

const handleErrors = (response) => {
  let message = '';
  let code = response?.status || 500;

  if (typeof response?.data === 'string') {
    if (response?.status === 500) {
      throw new ApiError('Some occurred. Please try later.', code);
    }

    if (response?.status === 404) {
      throw new ApiError('Resource not found. Please try later.', code);
    }

    if (response?.status === 401) {
      throw new ApiError('You are not authenticated. Please login.', code);
    }

    if (response?.status === 403) {
      throw new ApiError(
        'You are not authorized to perform this action.',
        code,
      );
    }

    if (response?.status === 405) {
      throw new ApiError('Method Not Allowed.', code);
    }
  }

  message =
    response?.data?.message ||
    response?.data?.detail ||
    response?.data?.errors ||
    response?.data?.error ||
    response?.message ||
    response;

  message =
    typeof message === 'object' ? Object.values(message).flat() : message;

  if (message?.includes('Network Error'))
    throw new ApiError('Some occurred. Please try later.', code);

  throw new ApiError(message, code);
};

export default handleErrors;
