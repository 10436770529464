import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from 'routes';

import { useUserStore } from 'store';

import ScrollToTop from 'hooks/ScrollToTop';
import ProtectedRoute from 'components/ProtectedRoute';

const checkHasAccess = (user, route) => {
  if (route?.redirectAuthUser && user)
    return {
      isAllowed: false,
      redirectPath: route.redirectAuthUser,
    };

  if (!route.private)
    return {
      isAllowed: true,
    };

  if (!user) {
    return {
      isAllowed: false,
      redirectPath: routes.ID.path,
    };
  }

  if (!route.allowedRoles)
    return {
      isAllowed: true,
    };

  return {
    isAllowed: route.allowedRoles.includes(user.role),
    redirectPath: '/404',
  };
};

const RoutesList = () => {
  const { user } = useUserStore((state) => state);

  return (
    <Routes>
      {Object.values(routes)
        .filter((route) => route.private)
        .map((route) => {
          const { isAllowed, redirectPath } = checkHasAccess(user, route);

          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <>
                  <ScrollToTop />
                  <ProtectedRoute
                    isAllowed={isAllowed}
                    redirectPath={redirectPath}
                  >
                    {route.element}
                  </ProtectedRoute>
                </>
              }
            />
          );
        })}

      {Object.values(routes)
        .filter((route) => !route.private)
        .map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <>
                  <ScrollToTop />
                  {route.element}
                </>
              }
            />
          );
        })}
    </Routes>
  );
};

export default RoutesList;
