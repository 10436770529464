import React from 'react';

import Search from './Search';
import Select from './Select';

import { projectStatuses, voteStatuses } from './config';

const Filters = ({ searchParameters, onSearchChange }) => {
  const updateParams = (key, value) => {
    onSearchChange((state) => ({
      ...state,
      [key]: value,
    }));
  };

  return (
    <div className="relative z-1 flex flex-wrap md:space-x-2 lg:space-x-3">
      <div className="mb-2 md:mb-0 w-full flex-1 md:w-fit">
        <Search onChange={(value) => updateParams('search', value)} />
      </div>
      <div className="flex space-x-2 md:space-x-3 w-full md:w-fit">
        <div className="w-1/2">
          <Select
            value={searchParameters.votes || voteStatuses[0].value}
            onChange={(value) => updateParams('votes', value)}
            name="Vote status"
            list={voteStatuses}
          />
        </div>

        <div className="w-1/2">
          <Select
            value={searchParameters.statuses || projectStatuses[0].value}
            onChange={(value) => updateParams('statuses', value)}
            name="Project status"
            list={projectStatuses}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
