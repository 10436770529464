import React, { useLayoutEffect } from 'react';

import ProfileHeader from 'components/common/ProfileHeader';
import LightDecotation from 'components/ui/LightDecoration';

import Connect from './Connect';
import Activity from './Activity';
import Settings from './Settings';
import Reports from './Reports';
import Achievements from './Achievements';
import Token from './Token';
import Earns from './Earns';

import { useSafeSoulStore } from 'store';

const Sidebar = () => {
  const { controls } = useSafeSoulStore();

  useLayoutEffect(() => {
    controls.getInfo();
  }, [controls]);

  return (
    <>
      <div>
        <ProfileHeader />

        <Connect />

        <Activity />

        <Settings />

        <Reports />
        <Achievements />
        <Token />
        <Earns />

        <LightDecotation hasLabel>
          <div>
            <span>Create my team</span>
          </div>
        </LightDecotation>

        <LightDecotation hasLabel>
          <div>Mint discount</div>
          <div>Extended wallet limit</div>
          <div>Early access to new collections</div>
        </LightDecotation>
      </div>
    </>
  );
};

export default Sidebar;
