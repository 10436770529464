import { devtools } from 'zustand/middleware';
import { create } from 'zustand';

import { getInformation, toggleLive } from '@/api/safesoul';

import defaultOptions from './defaultOptions';

const store = (set, get) => ({
  statistic: {
    activityDays: '-',
    projectsViewed: '-',
    scamViewed: '-',
  },
  votes: {},
  isFound: false,
  achievements: [],
  showLive: false,
  isLoaded: false,
  controls: {
    async getInfo() {
      if (get().isLoaded) return;

      try {
        const data = await getInformation();

        set({
          achievements: data.achievements,
          role: data.role,
          statistic: {
            activity_days: data.activity_days,
            projects_viewed: data.projects_viewed,
            scam_viewed: data.scam_viewed,
          },
          votes: data.votes,
          isLoaded: true,
          isFound: true,
          showLive: data.show_live,
        });
      } catch (error) {
        console.error(error);
      } finally {
        set({ isLoaded: true });
      }
    },

    async switchLive(data) {
      try {
        await toggleLive(data);

        set({ showLive: data });
      } catch (error) {
        console.error(error);
      }
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | SafeSoul Store',
};

const useSafeSoulStore = create(devtools(store, options));

export default useSafeSoulStore;
