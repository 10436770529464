import React, { useState } from 'react';
import classname from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';

const Select = ({ name, list, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const handleSelect = (value) => {
    setIsOpen(false);
    onChange(value);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div onClick={toggleOpen} ref={ref} className="relative">
      <div
        className={classname(
          'flex items-center justify-between rounded-t-lg bg-darklight h-14 md:w-48 pl-4 py-2.5 pr-3 transition cursor-pointer',
          isOpen ? 'rounded-b-none' : 'rounded-b-lg',
        )}
      >
        <div className="">
          <div className="font-medium text-xs opacity-40">{name}</div>
          <div className="font-medium">
            {list.find((i) => i.value === value).label}
          </div>
        </div>
        <span>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 10.5029L12 14.5029L16 10.5029"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="absolute top-full right-0 left-0 bg-darklight rounded-b-lg px-4 pb-2 w-full shadow-lg">
          <ul>
            {list.map((option) => (
              <li
                key={option.value}
                className={classname(
                  ' cursor-pointer py-0.5 transition hover:opacity-70 font-medium',
                  value === option.value && 'opacity-70 pointer-events-none',
                )}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
