import React from 'react';

import Decoration from 'components/ui/Decoration';
import SvgIcon from 'components/ui/SvgIcon';

import RainbowConnectWallet from './RainbowConnectWallet';
import DelegateLogo from 'assets/icons/delegatecash-logo.svg';
import { useConnectionsStore } from 'store';

import { pushEventGTM } from 'utils/gtm';

const DefaultState = ({ title, onChangeState, onSignIn }) => {
  const connectionsStore = useConnectionsStore();

  const handleStartAuthProcess = (provider) => {
    let method = null;

    if (provider === 'email') {
      onChangeState('email-form');
      method = 'email';
    }

    if (provider === 'rainbow') {
      method = 'Rainbow';
    }

    if (provider === 'google') {
      method = 'google';
      connectionsStore.connectGoogle();
    }

    if (provider === 'apple') {
      method = 'apple';
      connectionsStore.connectApple();

    }

    if (!method) return;

    pushEventGTM({
      event: 'start_login',
      method
    });
  };

  return (
    <div>
      {title}

      <div className="flex gap-2">
        <div className="flex-1">
          <Decoration>
            <button
              type="button"
              className="relative rounded-2xl flex justify-center items-center p-0 overflow-hidden bg-dark h-14 w-full text-[#A2A2A2] transform-gpu grayscale transition hover:text-lightgrey hover:grayscale-0 gap-2 focus-within:outline-none"
              onClick={() => handleStartAuthProcess('email')}
            >
              <SvgIcon name="email" />
            </button>
          </Decoration>
        </div>

        {/* <div className="flex-1">
          <Decoration>
            <button
              type="button"
              onClick={() => handleStartAuthProcess('apple')}
              className="relative rounded-2xl flex justify-center items-center p-0 overflow-hidden bg-dark h-14 w-full text-[#A2A2A2] transform-gpu grayscale transition hover:text-lightgrey hover:grayscale-0 gap-2 focus-within:outline-none"
            >
              <SvgIcon name="apple" />
            </button>
          </Decoration>
        </div> */}

        <div className="flex-1">
          <Decoration>
            <button
              type="button"
              onClick={() => handleStartAuthProcess('google')}
              className="relative rounded-2xl flex justify-center items-center p-0 overflow-hidden bg-dark h-14 w-full text-[#A2A2A2] transform-gpu grayscale transition hover:text-lightgrey hover:grayscale-0 gap-2 focus-within:outline-none"
            >
              <SvgIcon name="google" />
            </button>
          </Decoration>
        </div>
      </div>

      <div className="mt-2">
        <RainbowConnectWallet
          onStartConnection={() => handleStartAuthProcess('rainbow')}
          onSignIn={onSignIn} />
      </div>

      {/* <div className="mt-2 flex items-center gap-2 px-2 text-grey text-xs">
        <img src={DelegateLogo} alt="" />
        We support Delegate solutions
      </div> */}
    </div>
  );
};

export default DefaultState;
