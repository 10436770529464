import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import defaultOptions from './defaultOptions';

const store = (set, get) => ({
  tokens: [],
  animals: [],
  tokenLoaded: false,

  controls: {
    addToken: (token) => {
      if (
        get().tokens.some(
          (item) =>
            parseInt(item.blockChainTokenID) ===
            parseInt(token.blockChainTokenID),
        )
      )
        return;

      const tokens = [...get().tokens, token];

      set({ tokens });
    },
    removeTokens: () => {
      set({ tokens: [] });
    },
    setAnimal: (group) => {
      const animals = [
        ...get().animals.filter((item) => item.title !== group.title),
        group,
      ];

      set({ animals });
    },
    setTokenLoaded: (tokenLoaded) => {
      set({ tokenLoaded });
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | User Token Store',
};

const useTokenStore = create(devtools(store, options));

export default useTokenStore;
