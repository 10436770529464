import React, { useEffect, useRef, useMemo } from 'react';
import Chart from 'chart.js/auto';

import { parseData } from './utils';

import { useWindowWidth } from '@react-hook/window-size';

const getStep = ({ data = [] }) => {
  const max = Math.max(...data);

  const value = Math.ceil(max / 2);
  const length = Math.pow(10, value.toString().length - 1);

  const result = Math.ceil(value / length) * length;

  return result;
};

const Layout = ({ labels, datasets }) => {
  const canvas = useRef(null);

  const width = useWindowWidth();

  const scales = useMemo(() => {
    if (!datasets.length || datasets.length === 1) {
      return {
        [datasets[0].yAxisID]: {
          min: 0,
          max:
            Math.max(...datasets[0].data.map((i) => i[datasets[0].yAxisID])) *
            1.1,
          border: {
            display: false,
          },
          grid: {
            tickLength: 0,
            drawBorder: false,
            color: 'rgba(255,255,255,0.1)',
            lineWidth: 1,
          },
          ticks: {
            beginAtZero: true,
            color: 'rgba(255,255,255,0.4)',
            stepSize: getStep(datasets[0]),
            callback: function (value, index, ticks) {
              return value > 999
                ? (value / 1000).toFixed(0) + 'k'
                : parseInt(value);
            },
          },
        },
      };
    }

    return {
      y: {
        type: 'linear',
        min: 0,
        max:
          Math.max(...datasets[0].data.map((i) => i[datasets[0].yAxisID])) *
          1.1,
        border: {
          display: false,
        },
        grid: {
          tickLength: 0,
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          lineWidth: 1,
          stepSize: getStep(datasets[0]),
        },
        ticks: {
          display: false,
        },
      },
      y1: {
        type: 'linear',
        min: 0,
        max:
          Math.max(...datasets[1].data.map((i) => i[datasets[1].yAxisID])) *
          1.1,
        border: {
          display: false,
        },
        grid: {
          tickLength: 0,
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          lineWidth: 0,
          stepSize: getStep(datasets[1]),
        },
        ticks: {
          display: false,
        },
      },
      y2: {
        type: 'linear',
        min: 0,
        max:
          Math.max(...datasets[2].data.map((i) => i[datasets[2].yAxisID])) *
          1.1,
        border: {
          display: false,
        },
        grid: {
          tickLength: 0,
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          lineWidth: 0,
          stepSize: getStep(datasets[2]),
        },
        ticks: {
          display: false,
        },
      },
      // TODO: hide scam statistic
      y3: {
        type: 'linear',
        min: 0,
        max:
          Math.max(...datasets[3].data.map((i) => i[datasets[3].yAxisID])) *
          1.1,
        border: {
          display: false,
        },
        grid: {
          tickLength: 0,
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          lineWidth: 0,
          stepSize: getStep(datasets[3]),
        },
        ticks: {
          display: false,
        },
      },
    };
  }, [datasets]);

  useEffect(() => {
    if (!canvas.current) return;

    Chart.defaults.font.size = 11;
    const chart = new Chart(canvas.current, {
      type: 'line',
      data: {
        datasets,
      },
      options: {
        interaction: {
          mode: 'index',
          intersect: false,
        },
        aspectRatio: width < 800 ? 1.5 : 4,
        layout: {},
        elements: {
          point: {
            backgroundColor: 'rgba(255,255,255,0.05)',
          },
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              color: 'rgba(255,255,255,0.4)',
              padding: 10,
              callback: function (value, index, ticks) {
                return '';
              },
            },
          },
          ...scales,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            padding: 16,
            cornerRadius: 8,
            titleFont: {
              size: 14,
              weight: 'bold',
            },
            titleMarginBottom: 13,
            bodySpacing: 8,
            bodyFont: {
              size: 14,
              weight: 'medium',
            },
            boxWidth: 12,
            boxHeight: 12,
            boxPadding: 5,
            usePointStyle: true,
            backgroundColor: 'rgba(25, 25, 25, 0.9)',
            callbacks: {
              title: function (context) {
                const date = parseData(context[0].label);

                if (date instanceof Date === false) {
                  return context[0].label;
                }

                return date.toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                });
              },
              labelColor: function (context) {
                const color = context.dataset.borderColor;
                return {
                  borderColor: color,
                  backgroundColor: color,
                };
              },
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y;
                }
                return label;
              },
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [canvas, datasets, scales, labels, width]);

  return (
    <>
      <canvas ref={canvas} className="aspect-[1/2]"></canvas>
    </>
  );
};

export default Layout;
