import React from 'react';

import DecorationGradient from 'components/ui/DecorationGradient';

import { useSafeSoulStore } from 'store';

const Activity = () => {
  const {
    statistic: { activity_days, projects_viewed, scam_viewed },
    role,
  } = useSafeSoulStore((state) => state);

  // if (!isLoaded) return <></>;

  let userRoleName = '-';

  switch (role) {
    case 'patrol':
      userRoleName = 'Patrol';
      break;
    case 'og_patrol':
      userRoleName = 'OG Patrol';
      break;
    case 'god_mode':
      userRoleName = 'God Mode';
      break;
    case 'scout':
      userRoleName = 'Scout';
      break;
    case 'observer':
      userRoleName = 'Observer';
      break;
    default:
      userRoleName = '-';
      break;
  }

  return (
    <div>
      <div className="mb-2 text-lightgrey text-lg font-bold">Activity</div>

      <DecorationGradient>
        <div className="px-3 py-2">
          <div className="mb-2 text-grey text-xs font-medium leading-none">
            Your role
          </div>
          <div className="text-2xl text-lightgrey font-bold leading-none">
            {role && userRoleName !== '-' ? (
              <div className="flex items-center gap-1 rounded-lg bg-black p-2 text-[10px] text-white w-fit">
                <i className="rounded-full bg-green h-2 w-2"></i>
                {userRoleName}
              </div>
            ) : (
              <div>{userRoleName}</div>
            )}
          </div>
        </div>
      </DecorationGradient>

      <div className="mt-2 flex gap-2">
        <div className="flex-1">
          <DecorationGradient>
            <div className="px-3 py-2">
              <div className="mb-2 text-grey text-xs font-medium leading-none">
                Activity days
              </div>
              <div className="text-2xl text-lightgrey font-bold leading-none">
                {activity_days || '-'}
              </div>
            </div>
          </DecorationGradient>
        </div>
        <div className="flex-1">
          <DecorationGradient>
            <div className="px-3 py-2">
              <div className="mb-2 text-grey text-xs font-medium leading-none">
                Viewed
              </div>
              <div className="text-2xl text-lightgrey font-bold leading-none">
                {projects_viewed || '-'}
              </div>
            </div>
          </DecorationGradient>
        </div>
        <div className="flex-1">
          <DecorationGradient>
            <div className="px-3 py-2">
              <div className="mb-2 text-grey text-xs font-medium leading-none">
                Scam proof
              </div>
              <div className="text-2xl text-lightgrey font-bold leading-none">
                {scam_viewed || '-'}
              </div>
            </div>
          </DecorationGradient>
        </div>
      </div>
    </div>
  );
};

export default Activity;
