import React from 'react';

import Page from 'templates/Page';

import Main from 'components/pages/Passes/Main/index.jsx';
import Sidebar from 'components/pages/Passes/Sidebar';

import useSoulPassStore from '@/hooks/useSoulPass';

const Passes = () => {
  useSoulPassStore();

  return <Page main={<Main />} sidebar={<Sidebar />} />;
};

export default Passes;
