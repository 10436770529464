export const periods = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
];

export const voteStatuses = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'scam',
    label: 'Scam',
  },
  {
    value: 'hacked',
    label: 'Hacked',
  },
  {
    value: 'phishing',
    label: 'Phishing',
  },
  {
    value: 'suspicious',
    label: 'Suspicious',
  },
  {
    value: 'verified',
    label: 'It`s not a scam',
  },
];

export const projectStatuses = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'scam',
    label: 'Scam',
  },
  {
    value: 'hacked',
    label: 'Hacked',
  },
  {
    value: 'phishing',
    label: 'Phishing',
  },
  {
    value: 'suspicious',
    label: 'Suspicious',
  },
  {
    value: 'verified',
    label: 'Verified',
  },
];
