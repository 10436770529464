import { isMobile } from 'react-device-detect';
import { useUserStore } from 'store';

const handleEventFromChildModal = (event) => {
  if (event.origin !== window.location.origin || !event?.data.type) return;

  if (event.data.type === 'reload') {
    window.location.reload();
    window.removeEventListener('message', handleEventFromChildModal);
  }

  if (event.data.type === 'updateUser') {
    useUserStore.getState().controls.updateUser();
    window.removeEventListener('message', handleEventFromChildModal);
  }
};

const openWindow = ({ url, height = 800, width = 700 }) => {
  if (!url) return console.error('url is required');

  if (isMobile) {
    window.location.href = url;

    return;
  }

  if (['https://twitter', 'https://x.com'].some((i) => url.includes(i))) {
    window.location.href = url;

    return;
  }

  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  window.addEventListener('message', handleEventFromChildModal);

  setTimeout(() => {
    const openedWindow = window.open(
      '',
      '_blank',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    );

    try {
      if (!openedWindow) throw new Error('Failed to open window');

      openedWindow.location.href = url;

      openedWindow.focus();

      var timer = setInterval(function () {
        if (openedWindow.closed) {
          clearInterval(timer);
          window.removeEventListener('message', handleEventFromChildModal);
        }
      }, 1000);
    } catch (e) {
      console.error(e);

      openedWindow && openedWindow.close();

      window.removeEventListener('message', handleEventFromChildModal);

      window.location.href = url;
    }
  });

  // return openedWindow;
};

export default openWindow;
