import React from 'react';
import classnames from 'classnames';

import { ReactComponent as ClubLabel } from 'assets/icons/projects/club-label.svg';

import styles from './statinfodouble.module.scss';

const StatInfoDouble = ({ hasLabel, top, bottom }) => {
  return (
    <div
      className={classnames(
        styles.wrapper,
        hasLabel ? styles['has-label'] : styles['no-label'],
      )}
    >
      <div className={styles.inner}>
        <div className={styles.top}>{top}</div>
        <div className={styles.bottom}>{bottom}</div>
      </div>
      {hasLabel ? (
        <div className={styles.label}>
          <ClubLabel />
        </div>
      ) : null}
    </div>
  );
};

export default StatInfoDouble;
