import axios from 'axios';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';

import { updateSoul } from '@/api/dynamicSouls';

import defaultOptions from './defaultOptions';
import useUserStore from './user';

const store = (set, get) => ({
  state: 'idle',
  nextUpdate: null,
  souls: [],
  attributes: [],
  isLoaded: false,
  isLocked: false,
  controls: {
    nextState() {
      set({ state: 'freeze' });
    },
    async loadState() {
      const URL = process.env.REACT_APP_DA_URL + '/api/dynamic-soul/status';

      const {
        data: { message },
      } = await axios.get(URL);

      if (get().state === 'render' && message?.state === 'done') {
        window.location.reload();
      }

      set({
        state: message?.state,
        nextUpdate: new Date(message?.next_update),
      });
    },

    setAttributes: (attributes) => {
      set({ attributes });
    },

    async loadSoul() {
      const wallet = useUserStore.getState().user.wallet_address;

      if (!wallet || get().isLoaded) return;

      const URL =
        process.env.REACT_APP_DA_URL + '/api/dynamic-soul?wallet=' + wallet;

      const {
        data: { message },
      } = await axios.get(URL);

      const user = message ?? null;

      const souls = user.souls.map((soul) => ({
        ...soul,
        attributes: Object.entries(soul.attributes)
          .filter(([key, val]) => val)
          .map(([key, val]) => ({
            ...val,
            ...val.data,
            image: val.image ? val.image : val.normalized_metadata.image,
            type: key,
          })),
      }));

      set({ souls });
    },
    async updateAttribute(attribute) {
      const wallet = useUserStore.getState().user.wallet_address;

      if (!wallet) return;

      const attributes = [...get().attributes].map((a) => {
        if (a.id !== attribute.id) return a;

        return {
          ...a,
          amount: a.amount - 1,
        };
      });

      const soul = {
        ...get().souls[0],
        attributes: [...get().souls[0].attributes, attribute],
      };

      updateSoul({
        attributes: soul.attributes.reduce((acc, curr) => {
          acc[curr.type] = curr;

          return acc;
        }, {}),
        wallet,
      });

      set({
        attributes,
        souls: [soul, attribute],
      });
    },
    removeAttribute(attribute) {
      const souls = [
        {
          ...get().souls[0],
          attributes: get().souls[0].attributes.filter(
            (s) => s.id !== attribute.id || s.type !== attribute.type,
          ),
        },
      ];

      const attributes = [...get().attributes].map((a) => {
        if (a.key !== attribute.key) return a;

        return {
          ...a,
          amount: a.amount + 1,
        };
      });

      updateSoul(souls[0]);

      set({ souls, attributes });
    },
  },
});

const options = {
  ...defaultOptions,
  name: 'SoulsClub | Digital Soul Store',
};

const useSafeSoulStore = create(
  devtools(subscribeWithSelector(store), options),
);

useSafeSoulStore.subscribe(
  ({ attributes, souls, isLoaded }) => [attributes, souls, isLoaded],
  ([attributes, souls, isLoaded]) => {
    if (!souls.length || !attributes.length || isLoaded) return;

    let _attributes = [...attributes];

    _attributes.forEach((attribute) => {
      if (souls[0].attributes.some((a) => a.key === attribute.key)) {
        attribute.amount = Number(attribute.amount) - 1;
      }
    });

    useSafeSoulStore.setState({ attributes: _attributes, isLoaded: true });
  },
);

useSafeSoulStore.subscribe(
  ({ state }) => state,
  (state) => {
    useSafeSoulStore.setState({ isLocked: state !== 'settings' });
  },
);

useSafeSoulStore.subscribe(
  ({ attributes, souls, isLoaded }) => [attributes, souls, isLoaded],
  ([attributes, souls, isLoaded]) => {
    if (!souls.length || !attributes.length || isLoaded) return;

    let _attributes = [...attributes];

    _attributes.forEach((attribute) => {
      if (souls[0].attributes.some((a) => a.key === attribute.key)) {
        attribute.amount = Number(attribute.amount) - 1;
      }
    });

    useSafeSoulStore.setState({ attributes: _attributes, isLoaded: true });
  },
);

useSafeSoulStore.subscribe(
  ({ state }) => state,
  (state) => {
    useSafeSoulStore.setState({ isLocked: state !== 'settings' });
  },
);

useSafeSoulStore.subscribe(
  ({ attributes, souls, isLoaded }) => [attributes, souls, isLoaded],
  ([attributes, souls, isLoaded]) => {
    if (!souls.length || !attributes.length || isLoaded) return;

    let _attributes = [...attributes];

    _attributes.forEach((attribute) => {
      if (souls[0].attributes.some((a) => a.key === attribute.key)) {
        attribute.amount = Number(attribute.amount) - 1;
      }
    });

    useSafeSoulStore.setState({ attributes: _attributes, isLoaded: true });
  },
);

useSafeSoulStore.subscribe(
  ({ state }) => state,
  (state) => {
    useSafeSoulStore.setState({ isLocked: state !== 'settings' });
  },
);

export default useSafeSoulStore;
