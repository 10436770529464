import React from 'react';
import classnames from 'classnames';

import Decoration from 'components/ui/Decoration';

import styles from './input.module.scss';

const Input = ({
  type = 'text',
  value,
  error = '',
  className,
  onChange = null,
  readOnly,
  BeforeIcon,
  AfterIcon,
  ...props
}) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const showError = !readOnly && error;

  return (
    <div className={styles.input}>
      <Decoration error={showError} isInteract={!readOnly}>
        <div className={styles['input-wrapper']}>
          <span>{BeforeIcon ? <BeforeIcon /> : null}</span>

          <input
            type={type}
            className={classnames(
              styles['input-control'],
              BeforeIcon && styles['input-control--with-before-icon'],
              AfterIcon && styles['input-control--with-after-icon'],
              className,
            )}
            value={value}
            readOnly={readOnly}
            onChange={handleChange}
            {...props}
          />

          {AfterIcon ? <AfterIcon /> : null}
        </div>
      </Decoration>

      {showError ? <div className={styles['input-error']}>{error}</div> : null}
    </div>
  );
};

export default Input;
