export const parseData = (data) => {
  const parts = data.split('-');
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const day = parseInt(parts[2]);

  if (isNaN(year) || isNaN(month) || isNaN(day)) return data;

  return new Date(year, month, day);
};

export const convertToK = (num) => {
  const val = Number(num);

  if (val >= 10000) return (val / 1000).toFixed(1) + 'k';

  return val;
};
