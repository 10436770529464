import React, { lazy } from 'react';

import Page from 'templates/Page';

import Sidebar from 'components/pages/ID/Sidebar';
import Onboarding from 'components/common/Onboarding';

import { useUserStore } from 'store';

const AuthPage = lazy(() => import('./Auth'));

const Id = () => {
  const { isAuthenticated } = useUserStore();

  if (!isAuthenticated) return <AuthPage />;

  return <Page main={<Onboarding />} sidebar={<Sidebar />} />;
};

export default Id;
