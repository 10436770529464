import React from 'react';

import ProfileHeader from 'components/common/ProfileHeader';

import LightDecotation from 'components/ui/LightDecoration';

import SoulStatts from '@/components/common/SoulStatts';

const Sidebar = () => {
  return (
    <>
      <div>
        <ProfileHeader />

        <LightDecotation hasLabel>
          <div>
            <span>Watching other people's souls</span>
          </div>
        </LightDecotation>

        <SoulStatts
          cloned={{
            count: '-',
            perks: 0,
          }}
          merged={{
            count: '-',
            perks: 0,
          }}
          linked={{
            count: '-',
            perks: 0,
          }}
        />
      </div>
    </>
  );
};

export default Sidebar;
