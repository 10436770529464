import React from 'react';

import styles from './connect.module.scss';

import { ReactComponent as SafeSoul } from 'assets/icons/projects/safesoul.svg';
import { useSafeSoulStore } from 'store';
import { SAFESOUL_SITE_LINK } from 'constants/urls';

const Connect = () => {
  const { isFound, isLoaded } = useSafeSoulStore((state) => state);

  if (!isLoaded || (isLoaded && isFound)) return <></>;

  return (
    <div className={styles['no-connected']}>
      <div className={styles['title']}>
        <SafeSoul />
        SafeSoul
      </div>

      <div className={styles['text']}>
        Community driven safety platform, showing scam alerts to shield users on
        Twitter, Google, and all other webpages.
      </div>

      <div className={styles['link']}>
        <div>
          <p>More info</p>
          <p>
            <a href={SAFESOUL_SITE_LINK} target="__blank">
              {SAFESOUL_SITE_LINK.replace('https://', '')}
            </a>
          </p>
        </div>
        <a
          href="https://chromewebstore.google.com/detail/safesoul/llgphbakeoccbjgkhjlknapfhglnmcap"
          className="flex gap-1 justify-center items-center rounded-lg h-9 w-40 font-HelveticaDisplay font-extrabold text-sm text-sblack bg-green hover:bg-green/80 transition-all"
          target="__blank"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m12.501 13.834-1.778-1.778M14.279 12.056 12.5 13.834M12.5 8.499v5.335M16.503 14.723v0c0 .982-.796 1.779-1.778 1.779h-4.446A1.779 1.779 0 0 1 8.5 14.723v0"
              stroke="#1C1C1C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="12.5"
              cy="12.5"
              r="9.004"
              stroke="#1C1C1C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Install for free
        </a>
      </div>
    </div>
  );
};

export default Connect;
