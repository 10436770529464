import React from 'react';

import Input from 'components/ui/Input';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './emailOutput.module.scss';

const EmailOutput = ({ email, changeState, disable = false }) => {
  return (
    <div className={styles['email-wrapper']}>
      <Input
        type="email"
        name="email"
        placeholder="Your e-mail"
        value={email}
        readOnly={true}
        BeforeIcon={() => <SvgIcon name="email" />}
      />

      {!disable && (
        <div className={styles['change-email']}>
          <button type="button" onClick={changeState}>
            Change e-mail
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailOutput;
