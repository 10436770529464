import React, { useCallback, useState, useMemo } from 'react';
import { useBus } from 'react-bus';

import StartState from './StartState';
import EmailForm from './EmailForm';
import Processing from 'components/common/Processing';
import OtpCode from './OtpCode';

import { sendOtp, loginOtp } from 'api/auth';

import useAuth from 'hooks/useAuth';

const DEFAULT_STATE = 'start-state';

const AuthComponent = ({ title }) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const [email, setEmail] = useState('');
  const [emailErrors, setEmailErrors] = useState(null);
  const [otpCodeErrors, setOtpCodeErrors] = useState(null);

  const { signIn } = useAuth();

  const bus = useBus();

  const sendEmail = useCallback(async (email) => {
    setEmail(email);

    try {
      setState('processing');

      await sendOtp({ email });

      localStorage.setItem('dateOTPSend', new Date().getTime());

      setState('code-state');
    } catch (error) {
      setEmailErrors(error.message);
      setState('email-form');
    }
  }, []);

  const signInUser = useCallback(
    async (tokens, method = null) => {
      signIn(tokens, method);

      bus.emit('modal:login:close');
    },
    [signIn, bus],
  );

  const sendOTP = useCallback(
    async ({ email, otp }) => {
      try {
        const tokens = await loginOtp({ email, token: otp });

        signInUser(tokens, 'email');
      } catch (error) {
        setOtpCodeErrors(error.message);
      }
    },
    [signInUser],
  );

  const renderView = useMemo(() => {
    if (state === 'start-state') {
      return (
        <StartState
          onChangeState={setState}
          onSignIn={signInUser}
          title={title}
        />
      );
    }

    if (state === 'email-form') {
      return (
        <EmailForm
          title={title}
          email={email}
          error={emailErrors}
          onSubmitEmail={sendEmail}
          clearEmailError={() => setEmailErrors(null)}
          backState={() => setState('start-state')}
        />
      );
    }

    if (state === 'processing') {
      return (
        <Processing text="We’ll email you a 6-digit code for a password-free log in" />
      );
    }

    if (state === 'code-state') {
      return (
        <OtpCode
          title={title}
          error={otpCodeErrors}
          email={email}
          onChangeState={setState}
          sendOtp={sendOTP}
          resendOtp={sendEmail}
          clearOtpError={() => setOtpCodeErrors(null)}
        />
      );
    }

    return null;
  }, [
    email,
    emailErrors,
    otpCodeErrors,
    sendEmail,
    sendOTP,
    signInUser,
    state,
    title,
  ]);

  return <>{renderView}</>;
};

export default AuthComponent;
