import client, { axios, clientAuth } from './client';

import {
  PROFILE,
  UPDATE_EMAIL,
  CONFIRM_EMAIL,
  DISCORD_AUTH,
  TWITTER_AUTH,
  TWITTER_AUTH_URL,
  DISCORD_AUTH_URL,
  ADD_WALLET,
} from 'constants/urls';

import handleErrors from '@/utils/handleErros.js';

export const getProfile = async () => {
  try {
    const { data } = await client(PROFILE, {
      method: 'GET',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const updateEmail = async (email) => {
  try {
    await client(UPDATE_EMAIL, {
      method: 'PUT',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
      data: {
        email,
      },
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const addWallet = async ({ signature, message }) => {
  try {
    await client(ADD_WALLET, {
      method: 'PUT',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
      data: {
        signature,
        message,
      },
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const confirmEmail = async ({ token, email }) => {
  try {
    await axios(CONFIRM_EMAIL, {
      method: 'POST',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
      data: {
        email,
        token,
      },
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const getDiscordUrl = async (redirect_url) => {
  try {
    const { data } = await clientAuth.get(DISCORD_AUTH_URL, {
      params: {
        redirect_url,
      },
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const confirmDiscord = async ({ code, state, redirect_url }) => {
  try {
    await client(DISCORD_AUTH, {
      method: 'POST',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
      data: { code, state, redirect_url },
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const disconnectDiscord = async () => {
  try {
    await client(DISCORD_AUTH, {
      method: 'DELETE',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const getTwitterUrl = async (redirect_url) => {
  try {
    const { data } = await clientAuth.get(TWITTER_AUTH_URL, {
      params: {
        redirect_url,
      },
    });

    return data;
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const confirmTwitter = async ({ code, state, redirect_url }) => {
  try {
    await client(TWITTER_AUTH, {
      method: 'POST',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
      data: { code, state, redirect_url },
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};

export const disconnectTwitter = async () => {
  try {
    await client(TWITTER_AUTH, {
      method: 'DELETE',
      baseURL: process.env.REACT_APP_AUTH_DOMAIN_API,
    });
  } catch (error) {
    console.error(error);

    handleErrors(error?.response || error);
  }
};
