export const WALLET_GET_NONCE = '/auth/wallet/session';
export const WALLET_VERIFY = '/auth/wallet/verify';

export const SEND_OTP = '/auth/otp/email';
export const LOGIN_OTP = '/auth/otp/login';

export const LOGIN_GOOGLE_URL = '/auth/google/url';
export const LOGIN_GOOGLE = '/auth/google/login';

export const LOGIN_APPLE_URL = '/auth/apple/url';
export const LOGIN_APPLE = '/auth/apple/login';

export const LOGOUT = '/auth/logout';
export const AUTH_REFRESH = '/auth/tokens/refresh';

export const PROFILE = '/user/profile';
export const UPDATE_EMAIL = '/user/email';
export const ADD_WALLET = '/user/wallet';
export const CONFIRM_EMAIL = '/user/email/confirm';

export const DISCORD_AUTH_URL = '/socials/discord/url';
export const DISCORD_AUTH = '/socials/discord';

export const TWITTER_AUTH_URL = '/socials/twitter/url';
export const TWITTER_AUTH = '/socials/twitter';

export const SAFESOUL_INFORMATION = '/api/user/statistic';
export const SAFESOUL_UPDATE_LIVE = '/api/user/live';

export const PORTAL_INFO = '/api/portal/info';
export const PORTAL_POINTS = '/api/points';

export const OPENSEA_LINK_DA = 'https://opensea.io/collection/di-animals';
export const BLUR_LINK_DA = 'https://blur.io/collection/di-animals';
export const LOOKSRARE_LINK_DA =
  'https://looksrare.org/collections/0x25593a50255bfb30ea027f6966417b0bf780401d';
export const MARKETPLACE_LINK_DA =
  'https://makersplace.com/marketplace/di-animals/';
export const RARIBLE_LINK_DA = 'https://rarible.com/di-animals';

export const TWITTER_LINK = ' https://x.com/SoulsClubETH';
export const DISCORD_LINK = 'https://discord.gg/soulsclub';

export const SOULS_CLUB = 'https://souls.club';
export const DIGITAL_ANIMALS_SITE_LINK = 'https://digitalanimals.souls.club';
export const GAME_SITE_LINK = 'https://game.souls.club';
export const DIGITAL_SOUL_SITE_LINK = 'https://digitalsouls.souls.club';
export const STORE_SITE_LINK = 'https://store.souls.club';
export const SAFESOUL_SITE_LINK = 'https://safesoul.club';

export const GITCOIN_PASSPORT = 'https://passport.gitcoin.co';
export const BORING_SECURITY = 'https://boringsecurity.com/';
export const DELEGATE = 'https://revoke.cash';
export const REVOKE = 'https://revoke.cash';

export const SAFESOUL_EXTENSION_MARKET_LINK = 'https://chromewebstore.google.com/detail/safesoul/llgphbakeoccbjgkhjlknapfhglnmcap';
export const GAME_IOS_TESTFLIGHT_LINK = 'https://testflight.apple.com/join/jhwY8h9L';