import React, { useState, useMemo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';
import { Link, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styles from './main.module.scss';
import { useUserStore } from 'store';

import Decoration from '@/components/ui/Decoration';

import {
  DIGITAL_ANIMALS_SITE_LINK,
  SAFESOUL_SITE_LINK,
  DIGITAL_SOUL_SITE_LINK,
  GAME_SITE_LINK,
  STORE_SITE_LINK,
} from 'constants/urls';

const onboarding = [
  {
    title: 'Welcome to souls.club',
    description: `We're building a multi-level ecosystem that combines blockchain visualization, gaming, social security platform and NFT mechanics.`,
    isFullScreen: true,
  },
  {
    title: 'Digital soul',
    description:
      'Dynamic blockchain visualization. Holders’ activity on socials and wallet data are transformed into art as a Decentralized ID',
    video: require('assets/onboarding/ds.mp4'),
    poster: require('assets/onboarding/ds.jpg'),
    isFullScreen: true,
    isDark: false,
    moreInfo: '/#',
  },
  {
    title: 'Digital Animals',
    description:
      'Sold-out NFT collection of 8k unique animals, generative art created by igor.',
    image: require('assets/onboarding/da.jpg'),
    isFullScreen: true,
    isDark: false,
    moreInfo: DIGITAL_ANIMALS_SITE_LINK,
  },
  {
    title: 'Digital Animals Game',
    description:
      'Mobile game, meditative adventure about the journey of the soul, embodied as animals, in a utopian world governed by AI.',
    video: require('assets/onboarding/game.mp4'),
    poster: require('assets/onboarding/game.jpg'),
    isDark: false,
    isFullScreen: false,
    moreInfo: '/#',
  },
  {
    title: 'SafeSoul',
    description:
      'Community driven safety platform, showing scam alerts to shield users on Twitter, Google, and all other webpages.',
    video: require('assets/onboarding/ss.mp4'),
    poster: require('assets/onboarding/ss.jpg'),
    isFullScreen: true,
    isDark: true,
    moreInfo: SAFESOUL_SITE_LINK,
  },
  {
    title: 'Store',
    description:
      'Specializes in producing physical clothing and home goods with digital fashion wearables and NFT attributes for metaverse.',
    video: require('assets/onboarding/store.mp4'),
    poster: require('assets/onboarding/store.jpg'),
    isFullScreen: true,
    isDark: false,
    moreInfo: '/#',
  },
  {
    title: 'Insects',
    description:
      'Upcoming NFT collection of 15,000 insects each with its own Digital Soul. Digital Animals NFT holders will receive a 1:1 Airdrop.',
    video: require('assets/onboarding/insects.mp4'),
    poster: require('assets/onboarding/insects.jpg'),
    isFullScreen: true,
    isDark: false,
    moreInfo: '/#',
  },
];

const Main = () => {
  const elem = useRef(null);
  const { isAuthenticated } = useUserStore();

  const { state } = useLocation();
  const width = useWindowWidth();

  const [currentIndex, setCurrentIndex] = useState(state?.indexOnboarding || 0);

  const currentItem = useMemo(() => {
    return onboarding[currentIndex];
  }, [currentIndex]);

  const handleScrollTo = () => {
    const authSection = document.getElementById('auth-section');

    if (authSection) authSection.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];

    onboarding.forEach((item) => {
      const image = item.poster || item.image || null;

      if (image && !head.querySelector(`link[href = "${image}"]`)) {
        const posterLink = document.createElement('link');
        posterLink.rel = 'preload';
        posterLink.as = 'image';
        posterLink.href = image;

        head.appendChild(posterLink);
      }
    });
  }, []);

  return (
    <div className={classnames(styles.slider)}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={currentIndex}
          classNames="card-transition"
          timeout={300}
          nodeRef={elem}
        >
          <div
            ref={elem}
            className={classnames(
              styles.onboarding,
              'flex flex-col items-center',
              width < 640 && !currentItem.isFullScreen
                ? 'bg-[#0F0F0F]'
                : 'justify-center',
              currentIndex === 0 && 'bg-dark',
            )}
          >
            {currentIndex > 0 ? (
              currentItem.video ? (
                <video
                  src={currentItem.video}
                  className={classnames(
                    styles.poster,
                    width < 640 && !currentItem.isFullScreen
                      ? 'mt-8 !h-[calc(100vh_-_480px)] mb-4'
                      : 'flex-1',
                  )}
                  poster={currentItem.poster}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              ) : (
                <img
                  src={currentItem.image}
                  className={classnames(
                    styles.poster,
                    width < 640 && !currentItem.isFullScreen ? '' : 'flex-1',
                  )}
                  alt="poster"
                />
              )
            ) : (
              <div className="block w-fit m-auto pb-48">
                <svg
                  width="194"
                  height="194"
                  viewBox="0 0 194 194"
                  className="w-32 h-32 sm:w-[194px] sm:h-[194px]"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="97" cy="97" r="97" fill="#C2E900" />
                  <circle
                    cx="97.0144"
                    cy="96.9985"
                    r="77.8425"
                    fill="#C2E900"
                  />
                  <circle
                    cx="97.0144"
                    cy="96.9985"
                    r="77.8425"
                    fill="black"
                    fillOpacity="0.33"
                  />
                  <circle cx="96.9969" cy="97.0096" r="58.2" fill="#C2E900" />
                  <circle
                    cx="96.9969"
                    cy="97.0096"
                    r="58.2"
                    fill="black"
                    fillOpacity="0.66"
                  />
                  <circle cx="97.0031" cy="96.9904" r="38.8" fill="#C2E900" />
                  <circle
                    cx="97.0031"
                    cy="96.9904"
                    r="38.8"
                    fill="black"
                    fillOpacity="0.99"
                  />
                  <circle
                    cx="96.9856"
                    cy="96.9966"
                    r="19.1575"
                    fill="#C2E900"
                  />
                  <circle
                    cx="96.9856"
                    cy="96.9966"
                    r="19.1575"
                    fill="#C2E900"
                  />
                </svg>
              </div>
            )}

            <div
              className={classnames(
                styles['onboarding-text'],
                currentItem.isDark ? 'text-sblack' : 'text-lightgrey',
                'absolute left-0 right-0 bottom-5 sm:bottom-7',
              )}
            >
              <div>
                <div className={styles['onboarding-text--content']}>
                  <div className={styles['onboarding-text--counter']}>
                    {currentIndex + 1} / {onboarding.length}
                  </div>

                  <div className={styles['onboarding-text--title']}>
                    {currentItem.title}
                  </div>
                  <div className={styles['onboarding-text--description']}>
                    {currentItem.description}
                  </div>
                </div>

                <div
                  className={classnames(
                    styles['onboarding-text--controll'],
                    'flex flex-wrap',
                  )}
                >
                  <div className="hidden sm:block flex-1"></div>
                  <div className="mb-7 sm:mb-0 w-full sm:w-fit text-green font-bold text-center mx-auto">
                    {currentIndex > 0 && (
                      <Link
                        to={currentItem.moreInfo}
                        state={{ indexOnboarding: currentIndex }}
                        className={classnames(
                          'transition-all hover:opacity-80',
                          currentItem.moreInfo === '/#' &&
                            'pointer-events-none',
                        )}
                      >
                        More info{currentItem.moreInfo === '/#' && ' soon'}
                      </Link>
                    )}
                  </div>
                  <div
                    className={classnames(
                      styles['onboarding-text--controll__btn-group'],
                      'flex-1 ml-auto justify-end',
                    )}
                  >
                    {currentIndex > 0 ? (
                      <Decoration
                        className={
                          styles['onboarding-text--controll__backWrapper']
                        }
                      >
                        <button
                          type="button"
                          className={classnames(
                            styles['onboarding-text--controll__back'],
                            'py-4 px-6 sm:py-5 sm:px-10 text-sm sm:text-base',
                          )}
                          onClick={() => setCurrentIndex((state) => state - 1)}
                        >
                          Back
                        </button>
                      </Decoration>
                    ) : null}
                    {currentIndex < onboarding.length - 1 ? (
                      <Decoration
                        className={
                          styles['onboarding-text--controll__nextWrapper']
                        }
                      >
                        <button
                          type="button"
                          className={classnames(
                            styles['onboarding-text--controll__next'],
                            'py-4 px-6 sm:py-5 sm:px-10 text-sm sm:text-base',
                          )}
                          onClick={() => setCurrentIndex((state) => state + 1)}
                          disabled={currentIndex === onboarding.length - 1}
                        >
                          Next
                        </button>
                      </Decoration>
                    ) : (
                      <></>
                    )}

                    {width <= 640 &&
                    !isAuthenticated &&
                    currentIndex === onboarding.length - 1 ? (
                      <Decoration
                        className={
                          styles['onboarding-text--controll__nextWrapper']
                        }
                      >
                        <button
                          type="button"
                          className={classnames(
                            styles['onboarding-text--controll__next'],
                            'py-4 px-6 sm:py-5 sm:px-10 text-sm sm:text-base',
                          )}
                          onClick={handleScrollTo}
                        >
                          Log in / register
                        </button>
                      </Decoration>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Main;
