import React from 'react';

import RainbowConnector from 'components/RainbowConnector';

import Header from './Header';
import Footer from './Footer';

import Login from '@/components/common/Login';
import ConnectTwitter from '@/components/common/ConnectTwitter';

const Layout = ({ children }) => {
  return (
    <RainbowConnector>
      <Header />

      <div className="min-h-screen">{children}</div>

      <Footer />

      <Login />
      <ConnectTwitter />

    </RainbowConnector>
  );
};

export default Layout;
