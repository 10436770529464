import { lazy } from 'react';

const ID = require('pages/contents/ID.jsx').default;
const Collection = require('pages/contents/Collection.jsx').default;
const Passes = require('pages/contents/Passes.jsx').default;
const Souls = require('pages/contents/Souls.jsx').default;
const SafeSoul = require('pages/contents/SafeSoul.jsx').default;
const Orders = require('pages/contents/Orders.jsx').default;
const Game = require('pages/contents/Game.jsx').default;

const Leaderboard = lazy(() => import('pages/contents/Leaderboard.jsx'));
const About = lazy(() => import('pages/contents/About.jsx'));
const Airdrop = lazy(() => import('pages/contents/Airdrop.jsx'));
const CreateTeam = lazy(() => import('pages/contents/CreateTeam.jsx'));
const ItemTeam = lazy(() => import('pages/contents/ItemTeam.jsx'));
const TokenItem = lazy(() => import('pages/contents/TokenItem.jsx'));
const MetaverseItem = lazy(() => import('pages/contents/MetaverseItem.jsx'));
const AttributeItem = lazy(() => import('pages/contents/AttributeItem.jsx'));
const DigitalSoul = lazy(() => import('pages/contents/DigitalSoul.jsx'));
const Partners = lazy(() => import('pages/contents/Partners.jsx'));

const GoogleCallback = lazy(() => import('pages/services/GoogleCallback.jsx'));
const AppleCallback = lazy(() => import('pages/services/AppleCallback.jsx'));
const DiscordCallback = lazy(
  () => import('pages/services/DiscordCallback.jsx'),
);
const TwitterCallback = lazy(
  () => import('pages/services/TwitterCallback.jsx'),
);
const ExtensionConnectTwitter = lazy(
  () => import('pages/services/extension/ConnectTwitter.jsx'),
);
const ExtensionConnectDiscord = lazy(
  () => import('pages/services/extension/ConnectDiscord.jsx'),
);
const RedirectToGameIOS = lazy(
  () => import('pages/services/temp/RedirectToGameIOS.jsx'),
);
const RedirectToGC = lazy(
  () => import('pages/services/temp/RedirectToGC.jsx'),
);
const VideoGameIOS = lazy(
  () => import('pages/services/temp/RedirectToVideoGameIOS.jsx'),
);
const PrivacyPolicy = lazy(() => import('pages/contents/PrivacyPolicy.jsx'));
const TermsConditions = lazy(
  () => import('pages/contents/TermsConditions.jsx'),
);
const NotFound = lazy(() => import('pages/contents/NotFound.jsx'));

const routes = {
  ID: {
    path: '/',
    name: 'ID',
    label: 'ID',
    element: <ID />,
    // allowedRoles: ['user'],
    // private: true,
  },
  Collection: {
    path: '/collection',
    name: 'Collection',
    label: 'Collection',
    element: <Collection />,
    // allowedRoles: ['user'],
    private: true,
  },
  TokenItem: {
    path: '/collection/:id',
    name: 'TokenItem',
    label: 'Token Info',
    element: <TokenItem />,
    goTo: (id) => `/collection/${id}`,
    // allowedRoles: ['user'],
    private: true,
  },
  MetaverseItem: {
    path: '/metaverse/:name',
    name: 'MetaverseItem',
    label: 'Metaverse Info',
    element: <MetaverseItem />,
    goTo: (name) => `/metaverse/${name}`,
    // allowedRoles: ['user'],
    private: true,
  },
  AttributeItem: {
    path: '/attribute/:address/:id',
    name: 'AttributeItem',
    label: 'Attribute Info',
    element: <AttributeItem />,
    goTo: (address, id) => `/attribute/${address}/${id}`,
    // allowedRoles: ['user'],
    private: true,
  },
  Passes: {
    path: '/passes',
    name: 'Passes',
    label: 'Passes',
    element: <Passes />,
    // allowedRoles: ['user'],
    private: true,
  },
  Souls: {
    path: '/souls',
    name: 'Souls',
    label: 'Souls',
    // element: <Souls />,
    element: <DigitalSoul />,
    // allowedRoles: ['user'],
    private: true,
  },
  'Digital Soul': {
    path: '/souls/:id',
    name: 'Digital Soul',
    label: 'Digital Soul',
    element: <DigitalSoul />,
    // allowedRoles: ['user'],
    private: true,
  },
  SafeSoul: {
    path: '/safesoul',
    name: 'SafeSoul',
    label: 'SafeSoul',
    element: <SafeSoul />,
    // allowedRoles: ['user'],
    private: true,
  },
  Orders: {
    path: '/orders',
    name: 'Orders',
    label: 'Orders',
    element: <Orders />,
    // allowedRoles: ['user'],
    private: true,
  },
  Game: {
    path: '/game',
    name: 'Game',
    label: 'Game',
    element: <Game />,
    // allowedRoles: ['user'],
    private: true,
  },
  Partners: {
    path: '/partners',
    name: 'Partners',
    label: 'Partners',
    element: <Partners />,
    // allowedRoles: ['user'],
    // private: true,
  },

  Airdrop: {
    path: '/airdrop',
    name: 'Airdrop',
    label: 'Airdrop',
    element: <Airdrop />,
    // allowedRoles: ['user'],
  },

  ConfirmInvite: {
    path: '/invite/:code',
    name: 'ConfirmInvite',
    label: 'ConfirmInvite',
    element: <Airdrop />,
  },

  AirdropAbout: {
    path: '/airdrop/about',
    name: 'About',
    label: 'About',
    element: <About />,
    // allowedRoles: ['user'],
  },

  MiniGames: {
    path: '/mini-games',
    name: 'MiniGames',
    label: 'Mini Games',
    element: <Leaderboard />,
    isDisabled: true,
    // allowedRoles: ['user'],
  },

  Leaderboard: {
    path: '/leaderboard',
    name: 'Leaderboard',
    label: 'Leaderboard',
    element: <Leaderboard />,
    // allowedRoles: ['user'],
  },

  CreateTeam: {
    path: '/team/create',
    name: 'CreateTeam',
    label: 'Create Team',
    element: <CreateTeam />,
    // allowedRoles: ['user'],
    private: true,
  },
  ItemTeam: {
    path: '/team/:slug',
    name: 'ItemTeam',
    label: 'Item Team',
    element: <ItemTeam />,
    goTo: (slug) => `/team/${slug}`,
    // allowedRoles: ['user'],
    // private: true,
  },

  ConfirmLink: {
    path: '/confirm-link',
    name: 'ConfirmLink',
    label: 'Confirm Link',
    element: <ID />,
  },

  PrivacyPolicy: {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    label: 'Privacy Policy',
    element: <PrivacyPolicy />,
  },

  TermsConditions: {
    path: '/terms-conditions',
    name: 'TermsConditions',
    label: 'Terms Conditions',
    element: <TermsConditions />,
  },

  NotFound: {
    path: '/*',
    name: 'NotFound',
    label: 'Not Found',
    element: <NotFound />,
  },
};

export const servicesRoutes = {
  DemoGameIOS: {
    path: '/demo-ios-game',
    name: 'DemoGameIOS',
    label: 'Demo Game IOS',
    element: <RedirectToGameIOS />,
  },
  VideoGameIOS: {
    path: '/video-demo-ios-game',
    name: 'VideoGameIOS',
    label: 'Video Game IOS',
    element: <VideoGameIOS />,
  },
  DemoGameGC: {
    path: '/gc',
    name: 'DemoGameGC',
    label: 'Demo Game GC',
    element: <RedirectToGC />,
  },
  GoogleCallback: {
    path: '/google/callback',
    name: 'GoogleCallback',
    label: 'Google callback',
    element: <GoogleCallback />,
  },
  DiscordCallback: {
    path: '/discord/callback',
    name: 'DiscordCallback',
    label: 'Discord callback',
    element: <DiscordCallback />,
  },
  TwitterCallback: {
    path: '/twitter/callback',
    name: 'TwitterCallback',
    label: 'Twitter callback',
    element: <TwitterCallback />,
  },
  AppleCallback: {
    path: '/apple/callback',
    name: 'AppleCallback',
    label: 'Apple callback',
    element: <AppleCallback />,
  },
  ExtensionConnectDiscord: {
    path: '/extension/connect-discord',
    name: 'ExtensionConnectDiscord',
    label: 'Extension Connect Discord',
    element: <ExtensionConnectDiscord />,
  },
  ExtensionConnectTwitter: {
    path: '/extension/connect-twitter',
    name: 'ExtensionConnectTwitter',
    label: 'Extension Connect Twitter',
    element: <ExtensionConnectTwitter />,
  },
};

export default routes;
