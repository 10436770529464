import React, { useState } from 'react';
import { useListener } from 'react-bus';

import Modal from '@/components/ui/Modal';
import Auth from 'components/common/Auth';

const ConnectTwitter = () => {
  const [title, setTitle] = useState('Log in');
  const [isOpen, setIsOpen] = useState(false);

  useListener('modal:login:open', (title = null) => {
    setIsOpen(true);
    title && setTitle(title);
  });

  useListener('modal:login:close', () => {
    setIsOpen(false);
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="!max-w-sm"
    >
      <div className="w-full p-4 rounded-3xl bg-[#0F0F0F]">
        <div className="px-2 pt-1 mb-4">
          <div className="mb-2 leading-none text-lightgrey text-[32px] font-HelveticaDisplay font-extrabold">
            {title}
          </div>
          <div className="text-xs text-grey leading-normal space-y-1">
            <p>Once authorized, you will be able to:</p>
            <ul>
              <li className="text-green">• Get Diamonds</li>
              <li>• Invite friends and get more</li>
              <li>• See your friends on the leaderboard</li>
              <li>• Create teams</li>
              <li>• Fly to Mars (no, go to Elon for that)</li>
            </ul>
            <p>Choose your preferred login method and LFG.</p>
          </div>
        </div>
        <Auth />
      </div>
    </Modal>
  );
};

export default ConnectTwitter;
