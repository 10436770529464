import React from 'react';
import { useSoulPassStore } from '@/store';

import ItemPass from './ItemPass';
import content from './passesContent';
import Onboarding from '@/components/common/Onboarding';

const Main = () => {
  const { passesInfo, passesLoaded } = useSoulPassStore();

  if (!passesLoaded) return <></>;

  if (!passesInfo) return <Onboarding />;

  const passesArray = Object.values(passesInfo).filter((pass) => pass.hasPass);

  if (passesArray.length === 0) return <Onboarding />;

  const passes = passesArray.sort((a, b) => Number(b.id) - Number(a.id));

  return (
    <div className="2xl:flex 2xl:gap-4">
      <div className="sm:w-96 2xl:w-80 2xl:mr-auto 2xl:flex-shrink-0 mb-4 2xl:mb-0 px-2 2xl:px-0">
        <div className="mb-2 text-[32px] text-lightgrey font-HelveticaDisplay font-extrabold leading-none">
          Soul Pass
        </div>

        <div className="text-grey text-sm space-y-4">
          <p>
            Each Soul Pass unlocks a range of exclusive benefits, including
            early access to unique mechanics and the opportunity to bring a
            Digital Soul into existence.
          </p>
        </div>
      </div>
      <div className="space-y-2 sm:space-y-4">
        {passes.map((item, index) => (
          <div key={item.id + '' + index}>
            <ItemPass {...content[item.type]} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
