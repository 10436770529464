import React, { useMemo, useCallback } from 'react';

import { useUserStore, useConnectionsStore } from 'store';

import SvgIcon from 'components/ui/SvgIcon';
import { pushEventGTM } from 'utils/gtm';

import ConnectedState from '../ConnectedState';
import Empty from '../Empty';
import ConnectionError from '../ConnectionError';

import useLocalStorageBus from '@/hooks/useLocalStorageBus';

import styles from '../Empty/emptyblock.module.scss';

const Twitter = () => {
  const { message: error, clear } = useLocalStorageBus('twitterError');
  const connectionsStore = useConnectionsStore();
  const { user, controls } = useUserStore((store) => store);

  const name = user?.twitter?.user_name || null;

  const disconnect = async () => {
    await connectionsStore.disconnectTwitter();
    controls.updateUser();
  };

  const clearError = useCallback(() => {
    clear();
    connectionsStore.resetTwitterError();
  }, [clear, connectionsStore]);

  const state = useMemo(() => {
    if (connectionsStore.errorConnectionTwitter || error) return 'error';
    if (name) return 'connected';

    return 'empty';
  }, [connectionsStore.errorConnectionTwitter, error, name]);

  if (state === 'connected') {
    return (
      <ConnectedState value={name} onDisconnect={disconnect}>
        <SvgIcon name="X" />
      </ConnectedState>
    );
  }

  if (state === 'error') {
    return (
      <ConnectionError onClick={clearError}>
        {connectionsStore.errorConnectionTwitter || error}
      </ConnectionError>
    );
  }

  const handleConnectTwitter = () => {
    connectionsStore.connectTwitter();

    pushEventGTM({
      event: 'start_connect',
      method: 'Twitter'
    });
  };

  return (
    <div>
      <Empty onClick={handleConnectTwitter}>
        <SvgIcon name="X" />
        <div className={styles.emptyTextBlock}>
          <div className={styles.emptyTextTitle}>Connect Twitter</div>
          <div className={styles.emptyText}>
            Allows you to create Soul and more
          </div>
        </div>
      </Empty>
    </div>
  );
};

export default Twitter;
