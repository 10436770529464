import React, { useCallback, useState } from 'react';
import truncateEthAddress from 'truncate-eth-address';

import { addWallet } from 'api/user';
import { pushEventGTM } from 'utils/gtm';
import { useUserStore } from 'store';

import useRainbowConnect from 'hooks/useRainbowConnect';

import Decoration from 'components/ui/Decoration';
import RainbowImg from 'assets/icons/rainbow.svg';

import ConnectedState from '../ConnectedState';
import Empty from '../Empty';
import ConnectionError from '../ConnectionError';
import SvgIcon from 'components/ui/SvgIcon';

import styles from '../Empty/emptyblock.module.scss';

const Rainbow = () => {
  const { user, controls } = useUserStore((store) => store);

  const [name, setValue] = useState(
    user?.wallet_address ? truncateEthAddress(user?.wallet_address) : null,
  );

  const onConnectWallet = useCallback(
    ({ address }) => {
      setValue(truncateEthAddress(address));

      controls.updateUser();

      pushEventGTM({
        event: 'connect',
        method: 'Rainbow'
      });
    },
    [controls],
  );

  const { error, isLoading, connectWallet, handleDisconnect } =
    useRainbowConnect(onConnectWallet, addWallet);

  const handleOpenConnectModal = useCallback(() => {
    if (isLoading || error) return;

    connectWallet();

    pushEventGTM({
      event: 'start_connect',
      method: 'Rainbow'
    });
  }, [connectWallet, isLoading, error]);

  const handleReconnect = useCallback(async () => {
    await handleDisconnect();
  }, [handleDisconnect]);

  if (error)
    return <ConnectionError onClick={handleReconnect}>{error}</ConnectionError>;

  if (isLoading) {
    return (
      <Decoration>
        <div className="py-2.5 px-4 flex items-center gap-2 text-xs text-lightgrey">
          <img src={RainbowImg} alt="rainbow" />

          <div className="flex items-center flex-1 h-7">Waiting Rainbow...</div>
        </div>
      </Decoration>
    );
  }

  return (
    <div>
      {name ? (
        <ConnectedState value={name}>
          <SvgIcon name="wallet" />
        </ConnectedState>
      ) : (
        <Empty onClick={handleOpenConnectModal}>
          <img src={RainbowImg} alt="rainbow" />
          <div className={styles.emptyTextBlock}>
            <div className={styles.emptyTextTitle}>Connect wallet</div>
            <div className={styles.emptyText}>
              Allows you to log in with your wallet and more
            </div>
          </div>
        </Empty>
      )}
    </div>
  );
};

export default Rainbow;
