import React from 'react';

import styles from './connect.module.scss';

import { ReactComponent as GameLogo } from 'assets/icons/projects/game-logo.svg';

const Connect = () => {
  return (
    <div className={styles.connect}>
      <div className={styles.title}>
        <GameLogo />
        <span>The Game</span>
      </div>

      <div className={styles.text}>
        Mobile game, meditative adventure about the journey of the soul,
        embodied as animals, in a utopian world governed by AI.
      </div>
    </div>
  );
};

export default Connect;
